import { ColorThemes } from "../../../../pages/components/Casticulate/themes/colorThemes";

export function getAssessmentLongAnswerTemplate(theme: string | null) {
  const colorTheme = ColorThemes[theme as string] || ColorThemes["default"];

  const ASSESSMENT_TEMPLATE_STRING_INPUT = `  
<div class="w-full flex flex-col ${colorTheme.base} h-full px-4 py-2 md:px-8 md:py-4 rounded-lg">

  <div class="flex font-titillium justify-between items-center">
    <div class="flex text-4xl font-black whitespace-nowrap w-fit mr-8" > 
      <p class=" text-teal-600 mr-1 ">GBF</p>  
      <p class=" text-teal-800 " >Class Builder</p>
    </div>

    <!-- Progress Bar -->
    <div class="hidden md:flex bg-[#B8DED9] w-fit h-fit p-2 rounded-full items-center text-[#038B8A] font-bold">
      {{#progressBar}}
        <section class="bg-white rounded-full p-1 relative w-fit h-fit space-x-1 flex items-center justify-center">
          <i class="material-icons " >
            {{#data.icon}}{{data.icon}}{{/data.icon}}{{^data.icon}}chevron_left{{/data.icon}} 
          </i> 
          <p class="text-sm w-fit line-clamp-1 font-bold text-center {{#lastVisitedTitlePage}}{{id}}{{/lastVisitedTitlePage}}" > 
            {{data.title}}  
          </p>
        </section>
        <div class="h-2 w-4 bg-white {{#lastItemInProgressBar}}{{id}}{{/lastItemInProgressBar}}  " > 
        </div>
      {{/progressBar}}
    </div>
  </div>
  <!-- ASSESSMENT TEMPLATE -->


  <section id="questionSection" class="flex-1 min-h-[20dvh] max-h-[60dvh] w-full bg-white px-8  md:flex  md:flex-row space-y-0 md:space-x-4 justify-between py-4 overflow-y-auto rounded-3xl my-4 relative">
    <div class="w-full md:w-3/5">
      <div class="flex items-center">
        <i id="feedbackIcon" class="material-icons material-symbols-outlined text-lg text-teal-700"></i>
        <p id="feedbackText" class="text-teal-700 font-semibold text-lg " >{{feedbackText}}</>
      </div>
      <p id="question" class="font-semibold text-lg mt-2 md:break-words md:hyphens-auto prose">
      </p>
    </div>

    <img src={{questionImage}} class="{{questionImage}}  flex-1 h-full w-full md:w-[50%] object-contain object-right overflow-auto md:top-0 md:bottom-0 md:sticky "/>
    <img src={{feedbackImage}} class="{{feedbackImage}}  flex-1 h-full w-full md:w-[50%] object-contain object-right overflow-auto md:top-0 md:bottom-0 md:sticky "/>
  </section>


  <section class="${colorTheme.gradientPanel} rounded-t-3xl h-full w-full px-4 py-4 flex-1 justify-between " >
    <textarea id="inputText" class="w-full rounded-3xl resize-none h-full text-xl disabled:opacity-75" {{disabledInput}} ></textarea>
  </section>

  <div class=" w-full  flex justify-between self-end ${colorTheme.gradientPanel}  px-4 pb-4 pt-2 box-border h-fit rounded-b-3xl" >
    <button id="backButton" class="text-white p-2 font-titillium font-semibold rounded-3xl border-2 border-white w-fit self-end active:opacity-70 hover:shadow-lg transition-all duration-150 " >
      <i class="material-icons  " id="previousPage" >arrow_back</i>
    </button>

    <button id="{{buttonId}}"  {{disabled}}  class="text-white p-2 font-titillium font-semibold md:px-4 md:py-2  rounded-3xl border-2 border-white w-fit self-end active:opacity-70 hover:shadow-lg transition-all duration-150 disabled:opacity-50 ml-auto hover:opacity-80   " >
      <span class="hidden md:inline-block">{{buttonText}}</span>   
      <i class="material-icons  " id="previousPage" >arrow_forward</i>
    </button>

  </div>
</div>`;

  if (theme === null || theme === undefined) {
    return ASSESSMENT_TEMPLATE_STRING_INPUT;
  }

  return ASSESSMENT_TEMPLATE_STRING_INPUT;
}
