import { useState, useEffect } from "react";
import { ExamResponseInput } from "../../../generated/graphql";
import {
  ActivityProps,
  getDefaultValue,
  QuestionHeader,
  getFeedbackColor,
} from "../../../pages/components/GbfSlide";
import { Image, ProgressBar } from "../../../atoms/components";
import { parseColorToStyle } from "../../../helpers/ColorHelper";
import { twMerge } from "tailwind-merge";

export function TallySlideActivity({
  examItem,
  handleResponse,
  savedResponses,
  rightPageFeedback,
  finalAppearance,
}: ActivityProps) {
  const [selected, setSelected] = useState(
    getDefaultValue({
      examItem,
      savedResponses,
    })
  );
  const [textToDisplay, setTextToDisplay] = useState<string>(
    examItem.choices?.[0]?.longInput || ""
  );
  const [showDropdown, setShowDropdown] = useState(false);

  const sortedTallyFeedback = rightPageFeedback?.tallyResults
    ?.map((tallyResult) => {
      return tallyResult;
    })
    .sort((a, b) => (b?.score || 0) - (a?.score || 0));

  const textStyle = finalAppearance?.finalTextStyle;

  useEffect(() => {
    const response: ExamResponseInput = {
      item: examItem.uuid,
      choices: selected ? [selected as string] : [],
    };

    handleResponse(response);
  }, [selected]);

  const hasAnswered = savedResponses.some(
    (response) => response.item === examItem.uuid
  );

  return (
    <div className="w-full ">
      {!rightPageFeedback?.uuid ? (
        <div>
          <QuestionHeader text={examItem.text || ""} style={textStyle} />
          {examItem.choices?.map((choice) => {
            return (
              <button
                key={choice?.uuid}
                onClick={() => setSelected(choice?.uuid || "")}
                className={twMerge(
                  ` block w-full text-left text-lg p-4 mb-4 rounded-3xl border-black border-2 transition-all hover:bg-gray-300 active:bg-gray-400 ${getFeedbackColor(
                    {
                      choiceUuid: choice?.uuid || "",
                      hasAnswered: hasAnswered,
                      isCorrect: choice?.isCorrect || false,
                      selected: (selected as string) || "",
                      showResults: examItem.showResults || false,
                    }
                  )} ${hasAnswered ? "cursor-not-allowed" : ""}`
                )}
                style={textStyle}
                disabled={hasAnswered}
              >
                {/* <input type="radio" /> */}
                {choice?.longInput}
                {choice?.imageUrl || choice?.mediaUrl ? (
                  <Image
                    src={choice.imageUrl || choice.mediaUrl || ""}
                    className="mx-auto max-h-64"
                  ></Image>
                ) : (
                  <></>
                )}
                {choice?.audioUrl ? (
                  <audio controls className="w-full ">
                    <source src={choice.audioUrl} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                ) : (
                  <></>
                )}
              </button>
            );
          })}
        </div>
      ) : (
        <div>
          <h1 className="mb-8 font-bold text-gray-700">Results</h1>
          {sortedTallyFeedback?.map((tallyResult) => {
            return (
              <div key={tallyResult?.type?.uuid} className="mb-8">
                <h2 className="text-lg text-gray-700" style={textStyle}>
                  {tallyResult?.type?.name}
                </h2>
                <ProgressBar
                  value={
                    ((tallyResult?.score || 0) /
                      (rightPageFeedback.tallyResults?.length || 1)) *
                    100
                  }
                  max={100}
                />
                <div className="flex items-center justify-between w-full text-primary-500">
                  <p className="font-bold ">
                    {((tallyResult?.score || 0) /
                      (rightPageFeedback.tallyResults?.length || 1)) *
                      100}
                  </p>

                  <p className="text-xs ">
                    {`${tallyResult?.score} of ${
                      sortedTallyFeedback?.length
                    } question${sortedTallyFeedback?.length > 1 ? "s" : ""}`}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
