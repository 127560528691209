import { useSearchParams } from "react-router-dom";
import { Iframe } from "../../../atoms/components";
import { useQuery, gql } from "@apollo/client";

interface XapiAttributes {
  uri: string;
  resourceUuid?: string;
  courseUuid?: string;
  courseBlockUuid?: string;
  courseScheduleUuid?: string;
  actor: any;
}

export const ActorQuery = gql`
  query Account_and_user_details_screen {
    accountAndUserDetailsScreen: account_and_user_details_screen {
      userInfo: user_info {
        firstName: first_name
        userId: user_id
      }
    }
  }
`;

export interface ViewResourceXApiProps {
  uri: string;
}

export function ViewResourceXApi({ uri }: ViewResourceXApiProps) {
  const [searchParams] = useSearchParams();

  // URL PARAMS
  const courseBlockUuid = searchParams.get("course_block_uuid");
  const courseUuid = searchParams.get("course_uuid");
  const resourceUuid = searchParams.get("resource_uuid");
  const courseScheduleUuid = searchParams.get("course_schedule_uuid");

  const { data, loading, error } = useQuery(ActorQuery);

  const actor = data?.accountAndUserDetailsScreen?.userInfo;

  // LINK TO USE
  const xapiUri =
    searchParams.get("course_uuid") && data && !loading
      ? xapiUriConverter({
          uri: uri,
          courseBlockUuid: courseBlockUuid || "",
          courseUuid: courseUuid || "",
          courseScheduleUuid: courseScheduleUuid || "",
          actor: actor,
        })
      : xapiUriConverter({
          uri: uri,
          resourceUuid: resourceUuid as string,
          actor: actor,
        });

  // Wala po akong mapag basehan dito kung anong pwedeng gamitin to destinguish last page.
  // const handleShowXapiResources = () => {
  //   // Trigger an action to show xAPI resources within the iframe
  //   const iframe = document.getElementById("xapiFrame") as HTMLIFrameElement;

  //   console.log(iframe.contentWindow?.postMessage.length);
  // };
  return (
    <Iframe
      src={xapiUri}
      id="xapiFrame"
      data-testid="xapiFrame"
      className="h-full"
    ></Iframe>
  );
}

function xapiUriConverter(xapiAttributes: XapiAttributes) {
  const actor = `actor={ "name": "${xapiAttributes.actor?.firstName}" , "openid":"${process.env.REACT_APP_PASS_URI}/users/${xapiAttributes.actor?.userId}"}&`;
  const registration = `registration=${
    xapiAttributes.resourceUuid
      ? xapiAttributes.resourceUuid
      : xapiAttributes.courseBlockUuid
  }&`;
  const activityId = `activity_id=https://id.xapi.buri.io/objects/${
    xapiAttributes.courseUuid
      ? `buricourse/courses/${xapiAttributes.courseUuid}/schedules/${xapiAttributes.courseScheduleUuid}/blocks/${xapiAttributes.courseBlockUuid}`
      : `activity_id=https://id.xapi.buri.io/objects/buresource/resource/${xapiAttributes.resourceUuid}`
  }`;
  const convertedXapiLink =
    `${xapiAttributes.uri}` +
    `?endpoint=${window.location.origin}/api/burilrs/data/xAPI&` +
    `auth=Basic 1234&` +
    actor +
    registration +
    activityId;
  return convertedXapiLink;
}
