import {
  CheckboxItem,
  FillInTheBlanksItem,
  FreedomWallItem,
  MultipleChoiceItem,
  PollItem,
  TallyItem,
  TextItem,
} from "../../../molecules/components";
import {
  ExamSectionV2,
  ExamItemV2,
  InsideCourseResponses,
  OutsideCourseResponses,
} from "../../../pages/components/AssessmentViewerV2";
import { AssessmentItemFeedback } from "../../../molecules/components";
import { AnswerRequiredObject } from "../../../pages/components/AssessmentViewer";
import { ExamItemType } from "../../../molecules/components/AssessmentItemFeedback";
import { ExamResponseV2 } from "../ViewResourceBuriBooks";

export interface AssessmentSectionV2Props {
  section: ExamSectionV2;
  responses?: InsideCourseResponses[] | OutsideCourseResponses[];
  formData?: (data: any) => void;
  updateRequiredValue?: (requiredStateEntry: {
    isAnswered: boolean;
    itemUuid: string;
  }) => void;
  submissionStatus?: string;
}

export function AssessmentSectionV2({
  section,
  responses,
  formData = () => {},
  updateRequiredValue = (requiredStateEntry: AnswerRequiredObject) => {},
  submissionStatus = "",
}: AssessmentSectionV2Props) {
  return (
    <div className="mx-2 my-4 sm:mx-36 md:mx-48 lg:mx-64">
      {section?.items?.map((item: ExamItemV2 | null) => {
        let _response: InsideCourseResponses | OutsideCourseResponses = null;
        if (responses) {
          responses?.some(
            (response: InsideCourseResponses | OutsideCourseResponses) => {
              if (response?.item === item?.uuid) {
                _response = response;
                return true; // End the loop after finding a matching response
              }
              return false;
            }
          );
        }

        return (
          <div key={item?.uuid}>
            <AssessmentTypeSwitchV2
              item={item ?? {}}
              formData={formData}
              response={_response}
              updateRequiredValue={updateRequiredValue}
              submissionStatus={submissionStatus}
            />
            {submissionStatus === "final" ? (
              <AssessmentItemFeedback item={item ?? {}} response={_response} />
            ) : (
              <></>
            )}

            <hr className="my-8" />
          </div>
        );
      })}
    </div>
  );
}

export const AssessmentTypeSwitchV2 = ({
  item,
  formData,
  response,
  showResults,
  updateRequiredValue = (requiredStateEntry: {
    isAnswered: boolean;
    itemUuid: string;
  }) => {},
  submissionStatus,
}: {
  item: ExamItemV2;
  formData?: (data: any) => void;
  response?: InsideCourseResponses | OutsideCourseResponses | ExamResponseV2;
  showResults?: boolean;
  updateRequiredValue?: (requiredStateEntry: {
    isAnswered: boolean;
    itemUuid: string;
  }) => void;
  submissionStatus?: string;
}) => {
  const type = item.type;
  const inputDisabled = !!response || submissionStatus !== "";
  if (type === ExamItemType.MULTIPLE_CHOICE) {
    return (
      <MultipleChoiceItem
        item={item}
        formData={formData}
        inputDisabled={inputDisabled}
        updateRequiredValue={updateRequiredValue}
      />
    );
  } else if (type === ExamItemType.CHECK_BOX) {
    return (
      <CheckboxItem
        item={item}
        formData={formData}
        inputDisabled={inputDisabled}
        updateRequiredValue={updateRequiredValue}
      />
    );
  } else if (type === ExamItemType.POLL) {
    return (
      <PollItem
        item={item}
        formData={formData}
        inputDisabled={inputDisabled}
        updateRequiredValue={updateRequiredValue}
      />
    );
  } else if (
    type === ExamItemType.SHORT_ANSWER ||
    type === ExamItemType.FILL_IN_THE_BLANKS
  ) {
    return (
      <FillInTheBlanksItem
        item={item}
        formData={formData}
        inputDisabled={inputDisabled}
        updateRequiredValue={updateRequiredValue}
      />
    );
  } else if (type === ExamItemType.LONG_ANSWER) {
    return (
      <TextItem
        item={item}
        formData={formData}
        inputDisabled={inputDisabled}
        updateRequiredValue={updateRequiredValue}
      />
    );
  } else if (type === ExamItemType.FREEDOM_WALL) {
    return (
      <FreedomWallItem
        item={item}
        formData={formData}
        inputDisabled={inputDisabled}
        updateRequiredValue={updateRequiredValue}
      />
    );
  } else if (type === ExamItemType.TALLY) {
    return (
      <TallyItem
        item={item}
        formData={formData}
        inputDisabled={inputDisabled}
        showResults={showResults}
        updateRequiredValue={updateRequiredValue}
      />
    );
  } else return <></>;
};
