import React, { useEffect, useMemo, useContext, useLayoutEffect } from "react";
import { MustacheTemplateRenderer } from "../../../atoms/components";
import { useState } from "react";
import {
  CasticulateNode,
  SlideContext,
} from "../../../pages/components/Casticulate";
import camelcaseKeys from "camelcase-keys";
import { getAssessmentLongAnswerTemplate } from "./template/assessmentLongAnswerTemplate";
import { marked } from "marked";

export interface CasticulateAssessmentLongAnswerProps {
  node?: CasticulateNode;
}

export function CasticulateAssessmentLongAnswer({
  node,
}: CasticulateAssessmentLongAnswerProps) {
  const slideContext = useContext(SlideContext);

  const nodeData = camelcaseKeys(node?.data, { deep: true });

  const previousRecord = slideContext.slideStates.find(
    (slideState) => slideState.slideUuid === node?.id
  )?.assessmentSlideState;

  const [isCorrect, setIsCorrect] = useState(
    previousRecord?.isCorrect || false
  );
  const [hasAnswered, setHasAnswered] = useState(
    previousRecord?.hasAnswered || null
  );
  const [inputText, setInputText] = useState<string>(
    previousRecord?.answer || ""
  );

  const neutralAnswerFeedback = nodeData.neutralFeedbackMessage || "";

  const changedAnswer = previousRecord?.answer !== inputText;

  const ASSESSMENT_TEMPLATE_VIEW_STRING_INPUT = useMemo(
    () => ({
      question: node?.data.question || "",
      buttonText:
        hasAnswered && !changedAnswer ? "Continue" : "Answer to Continue",
      buttonId: hasAnswered && !changedAnswer ? "nextButton" : "submitButton",
      disabled: inputText === "" ? "disabled" : "",
      disabledInput: previousRecord ? "disabled" : "",
      questionImage: () => {
        return !hasAnswered && nodeData.imageUri ? nodeData.imageUri : "hidden";
      },
      feedbackImage: () => {
        return previousRecord?.answer === inputText &&
          nodeData.neutralFeedbackImage !== ""
          ? nodeData.neutralFeedbackImage
          : "hidden";
      },
      progressBar: slideContext.progressBar,
      lastVisitedTitlePage: function () {
        return function (val: any, render: any) {
          const id = render(val);
          const progressBarItemTitle = slideContext.progressBar.find(
            (progressBarItem) =>
              progressBarItem.id === slideContext.lastVisitedTitlePageId
          )?.data.title;
          return id === slideContext.lastVisitedTitlePageId &&
            (progressBarItemTitle ? progressBarItemTitle.trim() !== "" : false)
            ? "pr-4"
            : "hidden";
        };
      },
      lastItemInProgressBar: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return id ===
            slideContext.progressBar[slideContext.progressBar.length - 1].id
            ? "hidden"
            : "";
        };
      },
    }),
    [node, hasAnswered, inputText, previousRecord]
  );

  useLayoutEffect(() => {
    const textInputElement: HTMLTextAreaElement = document.getElementById(
      "inputText"
    ) as HTMLTextAreaElement;
    if (textInputElement !== null) {
      textInputElement.innerText = inputText;
      textInputElement.selectionStart = textInputElement.selectionEnd =
        textInputElement.value.length;
      textInputElement.focus();
    }

    slideContext.handleRerun();
  }, [inputText]);

  function textInputHandler(this: HTMLInputElement) {
    setInputText(this.value);
  }

  // Change Input Text
  useEffect(() => {
    // Get all button under an element with  id="choices"
    const textInputElement = document.getElementById("inputText");
    textInputElement?.addEventListener("input", textInputHandler);

    return () => {
      if (textInputElement)
        textInputElement.removeEventListener("input", textInputHandler);
    };
  }, [inputText, node, ASSESSMENT_TEMPLATE_VIEW_STRING_INPUT]);

  // Post Submissiong Use Effect
  useLayoutEffect(() => {
    const feedbackTextElement = document.getElementById("feedbackText");
    const feedbackIconElement = document.getElementById("feedbackIcon");
    const questionElement = document.getElementById("question");
    const textInputElement = document.getElementById("inputText");

    if (
      feedbackTextElement &&
      feedbackIconElement &&
      questionElement &&
      textInputElement !== null
    ) {
      if (previousRecord?.isCorrect === null) {
        feedbackIconElement.innerHTML = "info";
        feedbackIconElement.classList.add("mr-2");
        feedbackTextElement.innerHTML = "Thank you!";
        questionElement.innerHTML =
          marked.parse(neutralAnswerFeedback) || neutralAnswerFeedback;
      }

      if (
        hasAnswered === null ||
        (previousRecord?.isCorrect === null && changedAnswer)
      ) {
        feedbackIconElement.innerHTML = "";
        feedbackIconElement.classList.remove("mr-2");
        feedbackTextElement.innerHTML = nodeData.title;
        questionElement.innerHTML = nodeData.question;
      }
    }
  }, [
    previousRecord,
    isCorrect,
    inputText,
    ASSESSMENT_TEMPLATE_VIEW_STRING_INPUT,
  ]);

  useLayoutEffect(() => {
    const textInputElement = document.getElementById("inputText");
    if (textInputElement) textInputElement.innerText = inputText;
  }, [previousRecord]);

  const submitFunction = () => {
    setHasAnswered(true);
    slideContext.appendSlideStates({
      slideUuid: node?.id || "",
      assessmentSlideState: {
        activityType: node?.slideType || "",
        answer: inputText,
        isCorrect: null,
        hasAnswered: true,
      },
    });
  };

  useLayoutEffect(() => {
    const required = true;
    const submitButton = document.getElementById("submitButton");
    submitButton?.addEventListener("click", submitFunction);

    return () => {
      submitButton?.removeEventListener("click", submitFunction);
    };
  }, [ASSESSMENT_TEMPLATE_VIEW_STRING_INPUT, inputText]);

  return (
    <div className="box-border flex flex-col items-center h-full mx-auto">
      <MustacheTemplateRenderer
        template={getAssessmentLongAnswerTemplate(nodeData.theme)}
        view={ASSESSMENT_TEMPLATE_VIEW_STRING_INPUT}
      />
    </div>
  );
}
