import { useContext, useEffect, useMemo } from "react";
import {
  CasticulateNode,
  SlideContext,
  titilliumFontStyle,
} from "../../../pages/components/Casticulate";
import { MustacheTemplateRenderer } from "../../../atoms/components";
import camelcaseKeys from "camelcase-keys";
import { getResultSlideTemplate } from "./template/resultSlideTemplate";

export interface CasticulateResultsSlideProps {
  node?: CasticulateNode;
}

export function CasticulateResultsSlide({
  node,
}: CasticulateResultsSlideProps) {
  const slideContext = useContext(SlideContext);
  function generateStars(numberOfStars: number) {
    let stars = [];
    for (let i = 1; i <= numberOfStars; i++) {
      stars.push(i);
    }

    return stars;
  }

  const nodeData = camelcaseKeys(node?.data, {
    deep: true,
  });

  function replaceScorePlaceHolders(inputString: string) {
    if (!inputString) return false;

    return inputString
      .replace("{{rawScore}}", slideContext.resultsRawScore.toString())
      .replace("{{maxScore}}", slideContext.resultsMaxScore.toString());
  }

  const RESULTS_SLIDE_TEMPLATE_VIEW = useMemo(
    () => ({
      companyNameHeader: "GBF Class Builder",
      title: nodeData.title,
      // 5 stars max
      // lightedStars: Math.floor(
      //   (slideContext.resultsRawScore / slideContext.resultsMaxScore) * 5
      // ),
      feedback:
        replaceScorePlaceHolders(nodeData.feedback) ||
        `You got ${slideContext.resultsRawScore} out of ${slideContext.resultsMaxScore} stars!`,
      subtext:
        replaceScorePlaceHolders(nodeData.subtext || nodeData.subText) || "",
      rawScore: slideContext.resultsRawScore,
      maxScore: slideContext.resultsMaxScore,
      stars: generateStars(slideContext.resultsRawScore),
      starsFunction: function () {
        const numberOfStars = 5;
        const rawScore = slideContext.resultsRawScore || 0;
        const maxScore = slideContext.resultsMaxScore;
        const lightedStars = Math.floor((rawScore / maxScore) * numberOfStars);
        let starArray = [];
        for (let i = 0; i < numberOfStars; i++) {
          if (i < lightedStars) {
            starArray.push({ icon: "star" });
          } else {
            starArray.push({ icon: "star_outline" });
          }
        }
        return starArray;
      },
      imageUri: nodeData.imageUri,
      // isHidden: node?.slideType === "START" ? "hidden" : "flex",
      fontStyle: titilliumFontStyle,
      progressBar: slideContext.progressBar,
      lastVisitedTitlePage: function () {
        return function (val: any, render: any) {
          const id = render(val);
          const progressBarItemTitle = slideContext.progressBar.find(
            (progressBarItem) =>
              progressBarItem.id === slideContext.lastVisitedTitlePageId
          )?.data.title;
          return id === slideContext.lastVisitedTitlePageId &&
            (progressBarItemTitle ? progressBarItemTitle.trim() !== "" : false)
            ? "pr-4"
            : "hidden";
        };
      },
      lastItemInProgressBar: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return id ===
            slideContext.progressBar[slideContext.progressBar.length - 1].id
            ? "hidden"
            : "";
        };
      },
    }),
    [slideContext.progressBar, slideContext.lastVisitedTitlePageId]
  );

  return (
    <div className="box-border flex flex-col items-center h-[94vh] mx-auto">
      <MustacheTemplateRenderer
        template={getResultSlideTemplate(nodeData.theme)}
        view={RESULTS_SLIDE_TEMPLATE_VIEW}
      />
    </div>
  );
}
