import { useState } from "react";
import {
  CourseBlockOpenModals,
  CourseDefaultLayout,
  CourseListLayout,
} from "../../components";
import { Icon, Image, PillLabel, ProgressBar } from "../../../atoms/components";
import { CatalogListItem } from "../../../molecules/components";
import { CourseComplete } from "../../../molecules/components";
import { ForumBar } from "../../../organisms/components";
import {
  CourseBlock,
  CourseViewScreen,
} from "../../../pages/components/CourseViewer";
import { isGuestContext } from "../../../context/components/IsGuest";
import { useContext } from "react";
import { ColorObj, colorHelperBase } from "../../../helpers/ColorHelper";
import { CastColor } from "../../../generated/graphql";
import { dateFormattingSchedules } from "../../../helpers/DateFormatting";

export interface CourseViewerBlockProps {
  courseViewScreen?: CourseViewScreen;
  courseUuid: string;
  scheduleUuid?: string;
}

export function CourseViewerBlock({
  courseViewScreen,
  courseUuid,
  scheduleUuid,
}: CourseViewerBlockProps) {
  const [view, setView] = useState(true);
  const [isCourseBlockOpenModalsOpen, setIsCourseBlockOpenModalsOpen] =
    useState(false);

  const [currentBlock, setCurrentBlock] = useState<CourseBlock | undefined>();

  const course = courseViewScreen?.course;

  // Usable colors pero hindi po marerender hanggat hindi pinapakita yung value sa editor di ko lang din po alam anong reasonnn

  //       base: {
  //         style: colorHelperBase(
  //           data?.courseViewScreen.course?.styleInfo?.base.backgroundColor,
  //           data?.courseViewScreen.course?.styleInfo?.base.foregroundColor
  //         ),
  //         fontFamily:
  //           data?.courseViewScreen.course?.styleInfo?.base.fontFamily[0],
  //       },
  //       cardHeader: {
  //         style: colorHelperBase(
  //           data?.courseViewScreen.course?.styleInfo?.cardHeader.backgroundColor,
  //           data?.courseViewScreen.course?.styleInfo?.cardHeader.foregroundColor
  //         ),
  //         fontFamily:
  //           data?.courseViewScreen.course?.styleInfo?.base.fontFamily[0],
  //       },

  const baseStyle = {
    style: colorHelperBase(
      course?.styleInfo?.base?.backgroundColor as CastColor,
      course?.styleInfo?.base?.foregroundColor as CastColor
    ),
  };

  const cardStyle = {
    style: colorHelperBase(
      course?.styleInfo?.cardHeader?.backgroundColor as CastColor,
      course?.styleInfo?.cardHeader?.foregroundColor as CastColor
    ),
  };

  // const baseFont = ` font-[${course?.styleInfo.base.fontFamily}]`;
  // const cardFont = ` font-[${course?.styleInfo.cardHeader.fontFamily}]`;

  // const nextBlock: Block = {
  //   title: course.nextCourseBlocks[0].title,
  //   uuid: course.nextCourseBlocks[0].uuid,
  //   coverUri: course.nextCourseBlocks[0].coverUri,
  //   description: course.nextCourseBlocks[0].description,
  //   type: course.nextCourseBlocks[0].type,
  // };

  window.onbeforeunload = function () {
    window.history.replaceState({}, document.title);
  };

  const onCourseBlockClick = (block?: CourseBlock) => {
    setCurrentBlock(block);

    setIsCourseBlockOpenModalsOpen(true);
  };

  const { isGuest } = useContext(isGuestContext);
  return (
    <div>
      {isCourseBlockOpenModalsOpen ? (
        <CourseBlockOpenModals
          courseUuid={course?.uuid || ""}
          scheduleUuid={scheduleUuid}
          resourceUuid={currentBlock?.resource?.uuid as string}
          courseBlockUuid={currentBlock?.uuid as string}
          allowOpenModal={isCourseBlockOpenModalsOpen}
          onModalClose={() => {
            setIsCourseBlockOpenModalsOpen((prev) => !prev);
          }}
          expiryDate={currentBlock?.expiryDate}
        ></CourseBlockOpenModals>
      ) : (
        <div></div>
      )}
      {/* Cover */}
      <div
        className="flex flex-col w-full px-20 py-6 text-white bg-primary-500 "
        style={baseStyle.style as React.CSSProperties}
      >
        {/* Change View Div */}
        <div className="flex justify-end">
          <button
            className={
              (view ? "text-white" : "bg-white text-primary-600") +
              "  rounded-md p-1"
            }
            onClick={() => {
              if (view === true) setView(!view);
            }}
          >
            <Icon name="list"></Icon>
          </button>

          <button
            className={
              (view ? "bg-white text-primary-600" : " text-white") +
              " rounded-md p-1"
            }
            onClick={() => {
              if (view === false) setView(!view);
            }}
          >
            <Icon name="view_module"></Icon>
          </button>
        </div>
        {/* Course Header */}
        <div className="flex flex-col flex-wrap md:flex-row md:items-center ">
          <Image
            src={course?.coverUri || ""}
            className="w-32 h-32 mb-3 md:mb-0"
          ></Image>
          <div className="flex-1 md:ml-2">
            <div className="text-2xl ">
              <Icon name="school"></Icon>
            </div>
            <p className="text-2xl font-black">{course?.title}</p>

            <div className="my-2">
              {/* {course?.learnerCourseStatus.courseCertificateUrl ? ( */}
              {courseViewScreen?.learnerCourseStatus?.courseCertificateUrl ? (
                <PillLabel status="info_inverted">with certification</PillLabel>
              ) : (
                ""
              )}
            </div>

            {isGuest ? (
              <div></div>
            ) : (
              <div>
                <p className="text-sm">
                  <span className="font-bold">{`${courseViewScreen?.courseProgressValue}/${courseViewScreen?.courseProgressMax}`}</span>
                  resources completed
                </p>
                <ProgressBar
                  value={courseViewScreen?.courseProgressValue || 0}
                  max={courseViewScreen?.courseProgressMax || 0}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/*==================================================================================*/}

      {/* BATCH INFO */}
      <div className="flex items-center justify-between mx-20 my-6">
        <div className="flex text-slate-500">
          <Icon name="date_range" className="mr-1" />
          <div>
            <p>
              {courseViewScreen?.course?.enrollmentInfo?.name ||
                "No Batch Name"}
            </p>
            <p className="font-bold">
              {dateFormattingSchedules(
                courseViewScreen?.course?.enrollmentInfo?.start || ""
              )}
              {courseViewScreen?.course?.enrollmentInfo?.end
                ? `- ${dateFormattingSchedules(
                    courseViewScreen?.course?.enrollmentInfo?.end
                  )}`
                : ""}
            </p>
          </div>
        </div>

        <PillLabel
          status={
            courseViewScreen?.course?.enrollmentInfo?.isActive
              ? "in_progress"
              : ""
          }
          className={`${
            courseViewScreen?.course?.enrollmentInfo?.isActive
              ? " h-fit "
              : " hidden "
          }`}
        >
          ONGOING
        </PillLabel>
      </div>
      {/* BATCH INFO END */}
      {/* Next Resource & Course Completed ===============================================================*/}
      {isGuest ? (
        <div className="mb-6"></div>
      ) : (
        <div>
          {courseViewScreen?.learnerCourseStatus?.status === "passed" ? (
            <div className="mx-4 my-6 md:mx-20">
              <CourseComplete
                courseCertificateUrl={
                  courseViewScreen.learnerCourseStatus.courseCertificateUrl ||
                  ""
                }
              ></CourseComplete>
            </div>
          ) : (courseViewScreen?.nextCourseBlocks?.length || 0) > 0 &&
            courseViewScreen?.nextCourseBlocks ? (
            <div className="mx-20">
              {/* dunno why changing this from div to button changes its appearance, had to use another div to margin */}
              <button
                className="flex flex-col items-start w-full p-4 my-6 rounded-md bg-secondary-200 "
                onClick={() => {
                  onCourseBlockClick(
                    courseViewScreen?.nextCourseBlocks?.[0]
                      ? courseViewScreen?.nextCourseBlocks?.[0]
                      : undefined
                  );
                }}
              >
                <p className="mb-4 text-base font-bold text-secondary-500">
                  <span className="inline-block mr-1">
                    <Icon name="bookmark"></Icon>
                  </span>
                  This is your next resource
                </p>

                <div className="flex items-center">
                  <CatalogListItem
                    name={courseViewScreen?.nextCourseBlocks[0]?.title}
                    locked={false}
                    coverUri={courseViewScreen?.nextCourseBlocks[0]?.coverUri}
                    type={
                      courseViewScreen?.nextCourseBlocks[0]?.resource?.type ||
                      ""
                    }
                    subtype={
                      courseViewScreen.nextCourseBlocks[0]?.resource?.subtype ||
                      ""
                    }
                  ></CatalogListItem>
                </div>
                {courseViewScreen.nextCourseBlocks.length > 2 ? (
                  <p className="text-sm font-normal text-secondary-500">
                    {`+${
                      courseViewScreen.nextCourseBlocks.length - 1
                    } more resource(s)`}
                  </p>
                ) : (
                  <span className="-mb-2"></span>
                )}
              </button>
            </div>
          ) : (
            <div className="mb-6"></div>
          )}
        </div>
      )}
      {/*==================================================================================*/}
      {courseViewScreen?.isForumEnabled ? (
        <ForumBar courseUuid={courseUuid} />
      ) : (
        <div className="mb-6"></div>
      )}
      <div className="px-20">
        {view ? (
          <CourseDefaultLayout
            courseViewScreen={courseViewScreen}
            onCourseBlockClick={onCourseBlockClick}
            cardStyle={cardStyle as ColorObj}
          />
        ) : (
          <CourseListLayout
            courseViewScreen={courseViewScreen}
            onCourseBlockClick={onCourseBlockClick}
            cardStyle={cardStyle as ColorObj}
          />
        )}
      </div>
    </div>
  );
}
