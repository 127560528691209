import React from "react";
import { ReactComponent as CourseCompleteLogo } from "../../../assets/courseComplete.svg";
import { Link, useSearchParams } from "react-router-dom";

export interface CourseCompleteProps {
  courseCertificateUrl?: string;
}

export function CourseComplete({ courseCertificateUrl }: CourseCompleteProps) {
  const [searchParams] = useSearchParams();

  return (
    <div
      className={`bg-info-50 h-fit
      } rounded-md text-primary-700 text-center p-4 md:flex md:h-32 md:overflow-hidden md:justify-between `}
    >
      <CourseCompleteLogo
        className="w-full md:w-40 h-28 md:-mt-2 "
        data-testid={"completeCourseSvg"}
      />
      <div className="my-auto">
        <h2 className="text-lg font-bold md:text-2xl md:mx-auto md:flex-1">
          Course Completed!
        </h2>
        {courseCertificateUrl && (
          <Link
            to={{
              pathname: "/view-certificate",
              search: searchParams.toString(),
            }}
            className="underline "
          >
            {/* <a href={courseCertificateUrl as string}  */}
            view details
            {/* </p> */}
          </Link>
        )}
      </div>
      <div className="w-40"></div>
    </div>
  );
}
