import { ColorThemes } from "../../../../pages/components/Casticulate/themes/colorThemes";

export function getScrollCarouselTemplate(theme: string | null) {
  const colorTheme = ColorThemes[theme as string] || ColorThemes["default"];

  const SCROLL_IMAGE_CAROUSEL_TEMPLATE = `
<div class="w-full flex flex-col ${colorTheme.base} h-full px-4 py-2 md:px-8 md:py-4 rounded-lg space-y-4"> 
  <div class="flex justify-between items-center">
      <div class="flex font-titillium text-4xl font-black whitespace-nowrap w-fit mr-8" > 
        <p class=" text-teal-600 mr-1 ">GBF</p>  
        <p class=" text-teal-800 " >Class Builder</p>
      </div>

      <!-- Progress Bar -->
      <div class="hidden md:flex bg-[#B8DED9] w-fit h-fit p-2 rounded-full items-center text-[#038B8A] font-bold">
        {{#progressBar}}
          <section class="bg-white rounded-full p-1 relative w-fit h-fit space-x-1 flex items-center justify-center">
            <i class="material-icons " >
              {{#data.icon}}{{data.icon}}{{/data.icon}}{{^data.icon}}chevron_left{{/data.icon}} 
            </i> 
            <p class="text-sm w-fit line-clamp-1 font-bold text-center {{#lastVisitedTitlePage}}{{id}}{{/lastVisitedTitlePage}}" > 
              {{data.title}}  
            </p>
          </section>
          <div class="h-2 w-4 bg-white {{#lastItemInProgressBar}}{{id}}{{/lastItemInProgressBar}}  " > 
          </div>
        {{/progressBar}}
      </div>
  </div>


  <div class="flex-1 max-h-full w-full overflow-auto box-border " id="verticalCarousel">
    {{#uris}}
        <img class="mx-auto {{^isLastSlide}} mb-4 {{/isLastSlide}} object-contain h-[90%] w-full " src="{{uri}}" alt="image" />
    {{/uris}}
    <div id="sentry" class="h-1"></div>
  </div>



  <div id="navButtons" class=" w-full  flex justify-between self-end ${colorTheme.gradientPanel} px-4 py-4 box-border h-fit rounded-2xl items-center" >
    <button id="backButton" class="text-white p-2 font-titillium font-semibold rounded-3xl border-2 border-white w-fit self-end active:opacity-70 hover:shadow-lg transition-all duration-150 " >
      <i class="material-icons  " id="previousPage" >arrow_back</i>
    </button>
  </div>
</div>`;

  if (theme === null || theme === undefined) {
    return SCROLL_IMAGE_CAROUSEL_TEMPLATE;
  }

  return SCROLL_IMAGE_CAROUSEL_TEMPLATE;
}
