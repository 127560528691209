import { useContext, useEffect, useMemo } from "react";
import {
  CasticulateNode,
  SlideContext,
  titilliumFontStyle,
} from "../../../pages/components/Casticulate";
import { Image, MustacheTemplateRenderer } from "../../../atoms/components";
import { getTitleSlideTemplate } from "./template/titleSlideTemplate";
import camelcaseKeys from "camelcase-keys";

export interface CasticulateTitleSlideProps {
  node?: CasticulateNode;
}

export function CasticulateTitleSlide({ node }: CasticulateTitleSlideProps) {
  const slideContext = useContext(SlideContext);
  const nodeData = camelcaseKeys(node?.data, { deep: true });
  const TITLE_SLIDE_TEMPLATE_VIEW = useMemo(
    () => ({
      companyNameHeader: "GBF Class Builder",
      title: nodeData.title,
      imageUri: nodeData.imageUri || nodeData.image_uri,
      isHidden: node?.slideType === "START" ? "hidden" : "flex",
      fontStyle: titilliumFontStyle,
      progressBar: slideContext.progressBar,
      lastVisitedTitlePage: function () {
        return function (val: any, render: any) {
          const id = render(val);
          const progressBarItemTitle = slideContext.progressBar.find(
            (progressBarItem) =>
              progressBarItem.id === slideContext.lastVisitedTitlePageId
          )?.data.title;
          return id === slideContext.lastVisitedTitlePageId &&
            (progressBarItemTitle ? progressBarItemTitle.trim() !== "" : false)
            ? "pr-4"
            : "hidden";
        };
      },
      lastItemInProgressBar: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return id ===
            slideContext.progressBar[slideContext.progressBar.length - 1].id
            ? "hidden"
            : "";
        };
      },
    }),
    [slideContext, slideContext.lastVisitedTitlePageId]
  );

  return (
    <div className="box-border flex flex-col items-center h-full mx-auto">
      <MustacheTemplateRenderer
        template={getTitleSlideTemplate(nodeData.theme || null)}
        view={TITLE_SLIDE_TEMPLATE_VIEW}
      />
    </div>
  );
}
