import React, { useEffect, useState } from "react";
import {
  ExamItemV2,
  InsideCourseResponses,
  OutsideCourseResponses,
} from "../../../pages/components/AssessmentViewerV2";
import { Icon, Image } from "../../../atoms/components";
import { ExamResponseV2 } from "../../../organisms/components/ViewResourceBuriBooks";

export interface AssessmentItemFeedbackProps {
  item: ExamItemV2;
  response?: InsideCourseResponses | OutsideCourseResponses | ExamResponseV2;
}

export enum ExamItemType {
  MULTIPLE_CHOICE = "MC",
  SHORT_ANSWER = "SA",
  FILL_IN_THE_BLANKS = "FB",
  CHECK_BOX = "CB",
  POLL = "PO",
  LONG_ANSWER = "LA",
  FREEDOM_WALL = "FW",
  TALLY = "TA",
  I_CHOICE = "IC",
}

export const AssessmentItemFeedback = ({
  item,
  response,
}: AssessmentItemFeedbackProps) => {
  return (
    <div>
      {response ? (
        <FeedbackTypeSwitch item={item} response={response} />
      ) : (
        <></>
      )}
    </div>
  );
};

export const FeedbackTypeSwitch = ({
  item,
  response,
}: AssessmentItemFeedbackProps) => {
  const type = item.type;

  switch (type) {
    case ExamItemType.MULTIPLE_CHOICE:
    case ExamItemType.SHORT_ANSWER:
    case ExamItemType.FILL_IN_THE_BLANKS:
    case ExamItemType.CHECK_BOX: {
      return <StandardFeedback item={item} response={response} />;
    }
    case ExamItemType.LONG_ANSWER: {
      return <TextItemFeedback item={item} response={response} />;
    }
    case ExamItemType.POLL: {
      return <PollFeedback item={item} response={response} />;
    }
    case ExamItemType.TALLY: {
      return <TallyFeedback item={item} response={response} />;
    }
    case ExamItemType.FREEDOM_WALL: {
      return <FreedomWallFeedback item={item} response={response} />;
    }
    default: {
      return <></>;
    }
  }
};

const StandardFeedback = ({ item, response }: AssessmentItemFeedbackProps) => {
  return (
    <div>
      {item?.showResults ? (
        <div
          className={
            response?.isResponseCorrect
              ? "text-success-500 bg-success-50 my-4 py-2"
              : "text-error-500 bg-error-50 my-4 py-2"
          }
        >
          <div className="flex px-4">
            {response?.isResponseCorrect ? (
              <>
                <Icon name="check_circle" />
                <b>Correct</b>
              </>
            ) : (
              <>
                <Icon name="cancel" />
                <b>Incorrect</b>
              </>
            )}
          </div>
          <div className="px-8">
            <div className="text-left">{response?.feedbackMessage}</div>
            <Image
              src={response?.feedbackImageUrl || ""}
              alt="feedback image"
              className={`w-full object-contain min-h-24 max-h-[30rem] bg-opacity-0 ${
                response?.feedbackImageUrl ? "" : "hidden"
              }`}
            />
          </div>
        </div>
      ) : (
        <div className="w-full px-4 py-2 my-4 rounded bg-info-100 text-info-500">
          <b>Your answer has been submitted</b>
        </div>
      )}
    </div>
  );
};

const PollFeedback = ({ item, response }: AssessmentItemFeedbackProps) => {
  return (
    <div className="px-4 py-2 my-4 text-info-500 bg-info-50">
      <div className="font-bold">Your answer has been submitted</div>
      <div className="mb-8 text-left">{response?.feedbackMessage}</div>
      <div className="font-bold">Results:</div>
      {response?.pollResults?.map((pollResult) => (
        <div className="mb-8" key={pollResult?.choice?.uuid}>
          {/* Poll choice name */}
          <div className="my-2 text-black">
            {pollResult?.choice?.longInput || pollResult?.choice?.shortInput}
          </div>

          {/* Poll choice image */}
          <Image
            src={pollResult?.choice?.mediaUrl || ""}
            alt="poll image"
            className={`w-full object-contain min-h-24 max-h-[30rem] bg-opacity-0 ${
              pollResult?.choice?.mediaUrl ? "" : "hidden"
            }`}
          />

          {/* Poll choice information */}
          <div className="w-full h-4 my-2 rounded-full bg-info-200">
            <div
              className="flex items-center justify-center h-4 text-xs font-medium leading-none text-center rounded-full bg-info-500"
              style={{ width: `${pollResult?.result?.percent}%` }}
            ></div>
          </div>
          <div className="flex justify-between ">
            <div>{pollResult?.result?.count} vote(s)</div>
            <div className="font-bold">{pollResult?.result?.percent}%</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const TextItemFeedback = ({ item, response }: AssessmentItemFeedbackProps) => {
  return (
    <div className="px-4 py-2 text-info-500 bg-info-50">
      <div className="font-bold">Your answer has been submitted</div>
      <div>
        <div className="text-left">{response?.feedbackMessage}</div>
        <Image
          src={response?.feedbackImageUrl || ""}
          alt="feedback image"
          className={`w-full object-contain min-h-24 max-h-[30rem] bg-opacity-0 ${
            response?.feedbackImageUrl ? "" : "hidden"
          }`}
        />
      </div>
    </div>
  );
};

const TallyFeedback = ({ item, response }: AssessmentItemFeedbackProps) => {
  const [score, setScore] = useState<number>(0);

  useEffect(() => {
    response?.tallyResults?.forEach((tallyResult) => {
      setScore(tallyResult?.score || 0);
    });
  }, []);

  return (
    <div className="px-4 py-2 my-4 text-info-500 bg-info-50">
      <div className="font-bold">Your answer has been submitted</div>
      <div className="mb-8 text-left">{response?.feedbackMessage}</div>
      {response?.tallyResults &&
        response?.tallyResults.length > 0 &&
        item.showResults && <div className="mb-2 font-bold">Results:</div>}
      {response?.tallyResults &&
        response?.tallyResults.length > 0 &&
        item.showResults &&
        response?.tallyResults.map((tallyResult) => (
          <div className="mb-8" key={tallyResult?.type?.uuid}>
            {/* Tally choice name */}
            <div className="my-2 text-black">{tallyResult?.type?.name}</div>
            <div className="w-full h-4 my-2 rounded-full bg-info-200">
              <div
                className="flex items-center justify-center h-4 text-xs leading-none text-center rounded-full bg-info-500 font-mediu"
                style={{
                  width: `${Math.floor(
                    (tallyResult?.score || 0 / score) * 100
                  )}%`,
                }}
              ></div>
              <div className="flex justify-between mb-4">
                <div>{tallyResult?.score} vote(s)</div>
                <div className="font-bold">
                  {Math.floor((tallyResult?.score || 0 / score) * 100)}%
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

const FreedomWallFeedback = ({
  item,
  response,
}: AssessmentItemFeedbackProps) => {
  return (
    <div>
      <div className="px-4 py-2 my-4 text-info-500 bg-info-50">
        <div className="font-bold">Your answer has been submitted</div>
        <div>
          <div className="text-left">{response?.feedbackMessage}</div>
          <Image
            src={response?.feedbackImageUrl || ""}
            alt="feedback image"
            className={`w-full object-contain min-h-24 max-h-[30rem] bg-opacity-0 ${
              response?.feedbackImageUrl ? "" : "hidden"
            }`}
          />
        </div>
      </div>
      {/* Freedom Wall*/}
      <div className="w-full">
        <h2 className="mb-4 text-2xl font-bold">Responses</h2>
        {response?.freedomWallResponses?.map((freedomWallResponse) => {
          const profile = freedomWallResponse?.userDetails;
          return (
            <div
              key={freedomWallResponse?.uuid}
              className="w-full p-4 mb-2 bg-gray-200 rounded-lg "
            >
              <div className="flex items-center mb-4">
                <Image
                  className="w-8 h-8 mr-2 rounded-full"
                  src={profile?.profileIconUrl || ""}
                ></Image>

                <p className="font-semibold">
                  {/* Minsan po walang null na yung submitted by palang. Pag may laman naman minsan null din yung name. */}
                  {profile && profile.firstName && profile.lastName
                    ? `${profile.firstName} ${profile.lastName}`
                    : "Anonymous user"}
                </p>
              </div>
              <p>{freedomWallResponse?.longInput} </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
