import { gql } from "@apollo/client";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useHotkeys } from "react-hotkeys-hook";
import { useSearchParams } from "react-router-dom";
import { Image, Icon, Button } from "../../../atoms/components";
import {
  useAssessmentSubmitUpdateMutation,
  useAssessment_SubmissionMutation,
  useCourseBlockExamResponseMutation,
  useCourseBlockExamSubmitUpdateMutation,
  useCourse_Block_Exam_SubmissionMutation,
  useResourceEndMutation,
  useResourceStartMutation,
  useResourceViewScreenBuriBooksDetailedQuery,
  useAssessmentSubmitV2Mutation,
  ExamResponse,
} from "../../../generated/graphql";
import { LoadingCentered } from "../../../molecules/components";
import { AssessmentTypeSwitchV2 } from "../AssessmentSectionV2";
// import { AssessmentTypeSwitch } from "../AssessmentSection";
import { AssessmentItemFeedback } from "../../../molecules/components";
import { CamelCasedType } from "../../../helpers/components";

const MUTATION_COURSE_BLOCK_EXAM_RESPONSE = gql`
  mutation courseBlockExamResponse(
    $blockUuid: String!
    $response: CourseBlockExamSubmissionResponse
  ) {
    course_block_exam_response(block_uuid: $blockUuid, response: $response)
  }
`;
const MUTATION_COURSE_BLOCK_EXAM_SUBMIT_UPDATE = gql`
  mutation courseBlockExamSubmitUpdate(
    $blockUuid: String!
    $submission: CourseBlockExamSubmission
  ) {
    course_block_exam_submit_update(
      block_uuid: $blockUuid
      submission: $submission
    ) {
      submissionResult: submission_result {
        uuid
        timeslot
        responses {
          uuid
          item
          shortInput: short_input
          longInput: long_input
          mediaUrl: media_url
          choices {
            uuid
            item
            shortInput: short_input
            longInput: long_input
            mediaUrl: media_url
          }
          markedCorrect: marked_correct
          editable
          feedbackImageUrl: feedback_image_url
          feedbackMessage: feedback_message
          manualWeight: manual_weight
          isResponseCorrect: is_response_correct
          score
          createAt: created_at
          modifiedAt: modified_at
          user
          showGrade: show_grade
          freedomWallResponses: freedom_wall_responses {
            uuid
            shortInput: short_input
            longInput: long_input
            mediaUrl: media_url
            createAt: created_at
            modifiedAt: modified_at
            user
            submittedBy: submitted_by {
              name
              email
              userUuid: user_uuid
            }
          }
        }
        startedAt: started_at
        endedAt: ended_at
        totalScore: total_score
        editable
        final
        status
        submissionCount: submission_count
        highestPossibleScore: highest_possible_submission_score
        responsesScoresSum: response_scores_sum
        totalItemLimit: total_item_limit
        responsesCount: responses_count
        blockStatus: block_status {
          isComplete: is_complete
          status
          gradePercent: grade_percent
          scoreRaw: score_raw
          scoreMin: score_min
          scoreMax: score_max
        }
        certificateBlockStatuses: certificate_block_statuses {
          isEligible: is_eligible
          status
          userCertificateStatus: user_certificate_status
        }
        createAt: created_at
        modifiedAt: modified_at
      }
    }
  }
`;
const MUTATION_ASSESSMENT_SUBMIT_UPDATE = gql`
  mutation assessmentSubmitUpdate(
    $submissionUuid: String!
    $updatedSubmission: ExamSubmissionInput!
  ) {
    assessment_submit_update(
      submission_uuid: $submissionUuid
      updated_submission: $updatedSubmission
    ) {
      submissionResult: submission_result {
        status
        timeslot
        totalItemLimit: total_item_limit
        totalScore: total_score
        uuid
        submissionCount: submission_count
        responsesScoresSum: response_scores_sum
        responses {
          uuid
          mediaUrl: media_url
          markedCorrect: marked_correct
          feedbackImageUrl: feedback_image_url
          feedbackMessage: feedback_message
          score
          modifiedAt: modified_at
          pollChoice: poll_choice
          tallyResults: tally_results {
            type {
              uuid
              name
              description
            }
            score
          }
          pollResults: poll_results {
            choice {
              uuid
              shortInput: short_input
              longInput: long_input
              mediaUrl: media_url
              orderId: order_id
              audio_url
            }
            result {
              count
              percent
            }
          }
          longInput: long_input
          item
          isResponseCorrect: is_response_correct
          shortInput: short_input
          showGrade: show_grade
          choices {
            item
          }
          freedomWallResponses: freedom_wall_responses {
            uuid
            shortInput: short_input
            longInput: long_input
            mediaUrl: media_url
            createAt: created_at
            modifiedAt: modified_at
            user
          }
        }
      }
    }
  }
`;

// New update for freedom wall
const MUTATION_ASSESSMENT_SUBMIT_RESPONSE = gql`
  query resourceViewScreenBuriBooksDetailed($resourceUuid: ID!) {
    resourceViewScreen: resource_view_screen(resource_uuid: $resourceUuid) {
      resourceUuid: resource_uuid
      resource {
        uuid
        type
        name
        content {
          __typename
          ... on ResourceContentBurislide {
            examDetails: exam_details {
              examUuid: exam_uuid
              examTimeslotUuid: exam_timeslot_uuid
            }
            spreads {
              uri
              spreadType: spread_type
              leftUri: left_uri
              rightUri: right_uri
              examItemUuid: exam_item_uuid
              examTimeslotUuid: exam_timeslot_uuid
              uris
              text
              imageUri: image_uri
              title
              examItem: exam_item {
                uuid
                title
                text
                type
                subtype
                section
                instructions
                mediaUrl: media_url
                orderId: order_id
                isGraded: is_graded
                shuffleChoices: shuffle_choices
                weight
                value
                hint
                explanation
                required
                maxAllowedResponses: max_allowed_responses
                showResults: show_results
                choices {
                  uuid
                  item
                  shortInput: short_input
                  longInput: long_input
                  mediaUrl: media_url
                  imageUrl: image_url
                  audioUrl: audio_url
                  orderId: order_id
                  isCorrect: is_correct
                }
              }
            }
          }
          ... on ResourceContentBuribookWithActivity {
            examDetails: exam_details {
              examUuid: exam_uuid
              examTimeslotUuid: exam_timeslot_uuid
            }

            spreads {
              uri
              spreadType: spread_type
              leftUri: left_uri
              rightUri: right_uri
              examItemUuid: exam_item_uuid
              uris
              text
              imageUri: image_uri
              title
              examItem: exam_item {
                uuid
                title
                text
                type
                subtype
                section
                instructions
                mediaUrl: media_url
                orderId: order_id
                isGraded: is_graded
                shuffleChoices: shuffle_choices
                weight
                value
                hint
                explanation
                required
                maxAllowedResponses: max_allowed_responses
                showResults: show_results
                choices {
                  uuid
                  item
                  shortInput: short_input
                  longInput: long_input
                  mediaUrl: media_url
                  image_url
                  audio_url
                  orderId: order_id
                  isCorrect: is_correct
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type ExamResponseV2 = CamelCasedType<ExamResponse>;

interface Spread {
  spreadType: string;

  //  spreadType == 'slide'
  uri?: string;

  //  spreadType == 'activity'
  examItemUuid?: string;

  //  spreadType == 'image-spread'
  leftUri?: string;
  rightUri?: string;
}

export interface ViewResourceBuriBooksProps {
  onLastPage?: () => any;
  resourceUuid: string;
}

function useHooks() {
  const [spreadIndex, setSpreadIndex] = useState(0);
  const [imageSpreadIndex, setImageSpreadIndex] = useState(0);
  const [answeredExamItems, setAnsweredExamItems] = useState<any>({});

  const [searchParams] = useSearchParams();

  const resourceUuid = searchParams.get("resource_uuid");
  const courseBlockUuid = searchParams.get("course_block_uuid");
  const courseScheduleUuid = searchParams.get("course_schedule_uuid");

  const { data, error, loading } = useResourceViewScreenBuriBooksDetailedQuery({
    variables: {
      resourceUuid: resourceUuid || "",
    },
  });

  const content = data?.resourceViewScreen?.resource?.content;
  let spreads: Spread[] = [];
  let imageSpreads: Spread[] = [];

  if (
    content?.__typename === "ResourceContentBuribookWithActivity" ||
    content?.__typename === "ResourceContentBurislide"
  ) {
    const allSpreads = content.spreads as Spread[];

    spreads = allSpreads.filter(
      (s) => !(s.examItemUuid && answeredExamItems[s.examItemUuid])
    );
    imageSpreads = allSpreads.filter((s) => s.spreadType !== "activity");
  }

  let currentSpread: any;
  currentSpread = spreads[spreadIndex];

  const prevSpread = () => {
    if (currentSpread?.spreadType === "activity") {
      return;
    }

    setSpreadIndex((value) => {
      const newValue = clampSpreadIndex(value - 1, spreads.length);

      setImageSpreadIndex(
        spreads.filter((s, i) => i <= newValue && s.spreadType !== "activity")
          .length - 1
      );

      return newValue;
    });
  };
  const nextSpread = () => {
    if (currentSpread?.spreadType === "activity") {
      return;
    }

    setSpreadIndex((value) => {
      const newValue = clampSpreadIndex(value + 1, spreads.length);

      setImageSpreadIndex(
        spreads.filter((s, i) => i <= newValue && s.spreadType !== "activity")
          .length - 1
      );

      return newValue;
    });
  };

  const afterSubmit = () => {
    if (
      spreads.length - 1 >= spreadIndex + 1 &&
      spreads[spreadIndex + 1].spreadType !== "activity"
    ) {
      setImageSpreadIndex(imageSpreadIndex + 1);
    }
  };
  const onInputRangeChange = (event: any) => {
    if (currentSpread?.spreadType === "activity") {
      return;
    }
    setSpreadIndex(clampSpreadIndex(event.target.value, spreads.length));
  };

  useHotkeys("left", prevSpread);
  useHotkeys("right", nextSpread);
  const [
    assessmentSubmissionMutation,
    {
      data: assessmentSubmissionData,
      loading: assessmentSubmissionLoading,
      error: assessmentSubmissionError,
    },
  ] = useAssessment_SubmissionMutation();

  const [
    courseBlockExamSubmissionMutation,
    {
      data: courseSubmissionData,
      loading: courseSubmissionLoading,
      error: courseSubmissionError,
    },
  ] = useCourse_Block_Exam_SubmissionMutation();
  const [
    assessmentSubmitUpdateMutation,
    {
      data: assessmentSubmitUpdateData,
      loading: assessmentSubmitUpdateLoading,
      error: assessmentSubmitUpdateError,
    },
  ] = useAssessmentSubmitUpdateMutation();
  const [
    courseBlockExamSubmitUpdateMutation,
    {
      data: courseBlockExamSubmitUpdateData,
      loading: courseBlockExamSubmitUpdateLoading,
      error: courseBlockExamSubmitUpdateError,
    },
  ] = useCourseBlockExamSubmitUpdateMutation();

  // Submission Mutation for Buribooks
  // const [
  //   assessmentResponseMutation,
  //   {
  //     data: assessmentResponseData,
  //     loading: assessmentResponseLoading,
  //     error: assessmentResponseError,
  //   },
  // ] = useAssessmentResponseDetailedMutation();
  // const [
  //   courseBlockExamResponseMutation,
  //   {
  //     data: courseBlockExamResponseData,
  //     loading: courseBlockExamResponseLoading,
  //     error: courseBlockExamResponseError,
  //   },
  // ] = useCourseBlockExamResponseMutation();
  const [
    resourceStartMutateFunction,
    {
      loading: loadingResourceStart,
      data: dataResourceStart,
      error: errorResourceStart,
    },
  ] = useResourceStartMutation({
    variables: {
      resourceUuid: resourceUuid as string,
      courseBlockUuid,
      courseScheduleUuid,
    },
  });

  const [
    resourceEndMutateFunction,
    {
      loading: loadingResourceEnd,
      data: dataResourceEnd,
      error: errorResourceEnd,
    },
  ] = useResourceEndMutation({
    variables: {
      resourceUuid: resourceUuid as string,
      courseBlockUuid,
      courseScheduleUuid,
    },
  });

  useErrorHandler(error);

  const [
    assessmentSubmitV2,
    {
      data: assessmentResponseData,
      loading: assessmentResponseLoading,
      error: assessmentResponseError,
    },
  ] = useAssessmentSubmitV2Mutation();

  return {
    data,

    spreads,
    imageSpreads,
    currentSpread,

    loading: loading,

    resourceUuid,
    courseBlockUuid,

    spreadIndex,
    setSpreadIndex,
    imageSpreadIndex,
    setImageSpreadIndex,

    onInputRangeChange,

    nextSpread,
    prevSpread,
    afterSubmit,

    assessmentSubmissionMutation,
    assessmentSubmissionData,
    assessmentSubmissionLoading,
    assessmentSubmissionError,

    courseBlockExamSubmissionMutation,
    courseSubmissionData,
    courseSubmissionLoading,
    courseSubmissionError,

    assessmentSubmitUpdateMutation,
    assessmentSubmitUpdateData,
    assessmentSubmitUpdateLoading,
    assessmentSubmitUpdateError,

    // assessmentResponseMutation,
    // assessmentResponseData,
    // assessmentResponseLoading,
    // assessmentResponseError,

    // courseBlockExamResponseMutation,
    // courseBlockExamResponseData,
    // courseBlockExamResponseLoading,
    // courseBlockExamResponseError,

    courseBlockExamSubmitUpdateMutation,
    courseBlockExamSubmitUpdateData,
    courseBlockExamSubmitUpdateLoading,
    courseBlockExamSubmitUpdateError,

    answeredExamItems,
    setAnsweredExamItems,

    resourceStartMutateFunction,
    resourceEndMutateFunction,

    assessmentSubmitV2,
    assessmentResponseData,
    assessmentResponseLoading,
    assessmentResponseError,
  };
}

function SlideSpread(props: { uri?: string; pageNumber?: number }) {
  return (
    <Image
      src={props.uri || ""}
      className="w-full h-full"
      alt={props.pageNumber ? "Page " + props.pageNumber : undefined}
    />
  );
}

function ImageSpread(props: {
  leftUri?: string;
  rightUri?: string;
  pageNumber?: number;
}) {
  return (
    <div className="flex items-center justify-center h-full">
      <Image
        src={props.leftUri || ""}
        className="object-right w-full h-full"
        alt={props.pageNumber ? "Page " + props.pageNumber : undefined}
      />
      <Image
        src={props.rightUri || ""}
        className="object-left w-full h-full"
        alt={props.pageNumber ? "Page " + props.pageNumber : undefined}
      />
    </div>
  );
}

function ActivitySpread({
  item,
  submissionUuid,
  onNextPage,
}: {
  item?: any;
  submissionUuid: string;
  onNextPage?: () => any;
}) {
  const [response, setResponse] = useState<any>();
  const [submissionStatus, setSubmissionStatus] = useState<string>("");

  const {
    data,

    courseBlockUuid,

    // courseBlockExamResponseMutation,
    // courseBlockExamResponseData,
    // courseBlockExamResponseLoading,

    // assessmentResponseMutation,
    // assessmentResponseData,
    // assessmentResponseLoading,

    assessmentSubmitV2,
    assessmentResponseData,
    assessmentResponseLoading,
  } = useHooks();

  let resourceContent = data?.resourceViewScreen?.resource?.content;
  let examTimeslotUuid: string | null | undefined;
  let examUuid: string | null | undefined;

  if (resourceContent?.__typename === "ResourceContentBuribookWithActivity") {
    examTimeslotUuid = resourceContent.examDetails?.examTimeslotUuid;
    examUuid = resourceContent.examDetails?.examUuid;
  } else if (resourceContent?.__typename === "ResourceContentBurislide") {
    examTimeslotUuid = resourceContent.examDetails?.examTimeslotUuid;
    examUuid = resourceContent.examDetails?.examUuid;
  }

  let submissionResponse =
    assessmentResponseData?.assessmentSubmitUpdateV2
      ?.courseBlockSubmissionResult?.responses?.[0] ||
    assessmentResponseData?.assessmentSubmitUpdateV2?.submissionResult
      ?.responses?.[0];

  let submissionInfo =
    assessmentResponseData?.assessmentSubmitUpdateV2
      ?.courseBlockSubmissionResult ||
    assessmentResponseData?.assessmentSubmitUpdateV2?.submissionResult;

  let showResults;
  if (submissionInfo) {
    showResults =
      submissionInfo.responsesCount === submissionInfo.totalItemLimit;
  }

  const onSubmit = () => {
    if (
      examTimeslotUuid &&
      response &&
      ((response.choices && response.choices.length > 0) ||
        response.short_input ||
        response.long_input)
    )
      setSubmissionStatus("final");
  };

  const submitResponses = () => {
    if (
      examTimeslotUuid &&
      response &&
      ((response.choices && response.choices.length > 0) ||
        response.short_input ||
        response.long_input)
    ) {
      const _submission = {
        status: "final",
        timeslot: examTimeslotUuid ? examTimeslotUuid : "",
        responses: response,
      };

      assessmentSubmitV2({
        variables: {
          submissionUuid: submissionUuid,
          updatedSubmission: _submission,
          blockUuid: null,
        },
      });
    }
  };

  useEffect(() => {
    submitResponses();
  }, [submissionStatus]);

  useEffect(() => {
    setSubmissionStatus("");
  }, []);

  useEffect(() => {}, [submissionResponse]);

  return (
    <div className="relative h-full border-2 rounded-md md:mx-40">
      <div className="h-full p-10 overflow-y-auto">
        {assessmentResponseLoading ? (
          <LoadingCentered opacity={50}></LoadingCentered>
        ) : null}

        <AssessmentTypeSwitchV2
          showResults={showResults}
          item={item}
          key={item.uuid}
          response={submissionResponse}
          submissionStatus={submissionStatus}
          formData={(e) => {
            if (typeof e === "function") {
              let result = e();

              if (Object.keys(result).length > 0) {
                for (const [key, value] of Object.entries(result)) {
                  const response =
                    typeof value === "object"
                      ? { item: key, choices: value }
                      : courseBlockUuid
                      ? { item: key, short_input: value }
                      : { item: key, long_input: value, short_input: value };

                  setResponse(response);
                }
              }
            }
          }}
        />

        {submissionResponse ? (
          <AssessmentItemFeedback item={item} response={submissionResponse} />
        ) : (
          ""
        )}
      </div>
      {submissionResponse ? (
        <Button
          color="secondary"
          onClick={onNextPage}
          className="absolute bottom-0 w-full"
        >
          Next
        </Button>
      ) : (
        <Button onClick={onSubmit} className="absolute bottom-0 w-full">
          Submit
        </Button>
      )}
    </div>
  );
}

const clampSpreadIndex = (value: number, maxSpreadLength: number) => {
  return Math.max(0, Math.min(value, maxSpreadLength - 1));
};

export function ViewResourceBuriBooks({
  onLastPage,
}: ViewResourceBuriBooksProps) {
  const {
    data,

    spreads,
    imageSpreads,
    currentSpread,

    loading,
    spreadIndex,
    imageSpreadIndex,
    setImageSpreadIndex,
    onInputRangeChange,

    nextSpread,
    prevSpread,
    afterSubmit,

    answeredExamItems,
    setAnsweredExamItems,

    courseBlockUuid,

    assessmentSubmissionMutation,
    assessmentSubmissionData,
    assessmentSubmissionLoading,

    courseBlockExamSubmissionMutation,
    courseSubmissionData,
    courseSubmissionLoading,

    assessmentSubmitUpdateMutation,
    assessmentSubmitUpdateData,
    assessmentSubmitUpdateLoading,

    courseBlockExamSubmitUpdateMutation,
    courseBlockExamSubmitUpdateData,
    courseBlockExamSubmitUpdateLoading,
  } = useHooks();
  let resourceContent = data?.resourceViewScreen?.resource?.content;
  let examTimeslotUuid: string | null | undefined;
  let examUuid: string | null | undefined;

  if (resourceContent?.__typename === "ResourceContentBuribookWithActivity") {
    examTimeslotUuid = resourceContent.examDetails?.examTimeslotUuid;
    examUuid = resourceContent.examDetails?.examUuid;
  } else if (resourceContent?.__typename === "ResourceContentBurislide") {
    examTimeslotUuid = resourceContent.examDetails?.examTimeslotUuid;
    examUuid = resourceContent.examDetails?.examUuid;
  }
  // Ensure onLastPage is called only once
  useEffect(() => {
    if (spreadIndex === spreads.length - 1) {
      if (onLastPage) {
        onLastPage();
      }
    }
  }, [spreadIndex]);

  useEffect(() => {
    if (imageSpreadIndex === spreads.length - 1) {
      if (examTimeslotUuid) {
        if (courseBlockUuid) {
          courseBlockExamSubmitUpdateMutation({
            variables: {
              blockUuid: courseBlockUuid,
              submission: {
                status: "final",
                timeslot: examTimeslotUuid,
                uuid: courseSubmissionData?.course_block_exam_submission.uuid,
              },
            },
          });
        } else {
          assessmentSubmitUpdateMutation({
            variables: {
              submissionUuid:
                assessmentSubmissionData?.assessment_submission.uuid,
              updatedSubmission: {
                status: "final",
              },
            },
          });
        }
      }
      if (onLastPage) {
        onLastPage();
      }
    }
  }, [imageSpreadIndex]);

  useEffect(() => {
    if (examTimeslotUuid) {
      if (courseBlockUuid) {
        courseBlockExamSubmissionMutation({
          variables: {
            blockUuid: courseBlockUuid,
            submission: {
              status: "in_progress",
              timeslot: examTimeslotUuid,
            },
          },
        });
      } else {
        assessmentSubmissionMutation({
          variables: {
            examTimeslotUuid: examTimeslotUuid,
            submission: {
              status: "in_progress",
            },
          },
        });
      }
    }
  }, [examTimeslotUuid]);
  if (
    loading ||
    courseSubmissionLoading ||
    assessmentSubmissionLoading ||
    assessmentSubmitUpdateLoading ||
    courseBlockExamSubmitUpdateLoading
  ) {
    return <LoadingCentered />;
  }

  if (!spreads || spreads.length === 0) {
    throw new Error("No spreads found");
  }

  let spreadContent;
  if (currentSpread?.spreadType === "activity") {
    const examItemUuid = currentSpread.examItem?.uuid;

    spreadContent = (
      <ActivitySpread
        submissionUuid={
          assessmentSubmissionData?.assessment_submission.uuid ||
          courseSubmissionData?.course_block_exam_submission.uuid
        }
        key={examItemUuid}
        item={currentSpread.examItem}
        onNextPage={() => {
          setAnsweredExamItems((value: any) => {
            if (examItemUuid) {
              value = {
                ...value,
                [examItemUuid]: true,
              };
            }
            return value;
          });
          afterSubmit();
        }}
      ></ActivitySpread>
    );
  } else if (currentSpread?.spreadType === "slide") {
    spreadContent = (
      <SlideSpread
        uri={currentSpread?.uri || ""}
        pageNumber={spreadIndex + 1}
      ></SlideSpread>
    );
  } else if (currentSpread?.spreadType === "image-spread") {
    spreadContent = (
      <ImageSpread
        leftUri={currentSpread?.leftUri || ""}
        rightUri={currentSpread?.rightUri || ""}
        pageNumber={spreadIndex + 1}
      ></ImageSpread>
    );
  }

  return (
    <div className="h-[90vh] flex flex-col">
      <div className="relative flex-1 min-h-0 group">
        <button
          className="absolute left-0 invisible w-1/12 h-full text-4xl text-gray-600 group-hover:visible"
          onClick={prevSpread}
          aria-label="Previous page"
        >
          <Icon name="chevron_left"></Icon>
        </button>

        <button
          className="absolute right-0 invisible w-1/12 h-full text-4xl text-gray-600 group-hover:visible"
          onClick={nextSpread}
          aria-label="Next page"
        >
          <Icon name="chevron_right"></Icon>
        </button>

        {spreadContent}
      </div>

      <div className="flex flex-col mx-12 my-3">
        <input
          max={imageSpreads.length - 1}
          value={imageSpreadIndex}
          disabled
          step="1"
          type="range"
          onChange={onInputRangeChange}
          className="w-full h-2 appearance-none bg-primary-300"
        />
        <div className="flex justify-center space-x-4">
          Page {imageSpreadIndex + 1} of {imageSpreads.length}
        </div>
      </div>
    </div>
  );
}
