import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { CourseCard } from "./organisms/components/CourseCard";
import { ChoosePlan, Favicon } from "./organisms/components";
import {
  AccountAndUserDetails,
  ActivityLog,
  AssessmentViewerV2,
  AuthCallback,
  CatalogList,
  Confirmation,
  CourseActivity,
  CourseViewer,
  Diksiyonaryo,
  ErrorPage,
  ForumViewer,
  Home,
  Login,
  Logout,
  ProfileCoursesCertification,
  PurchaseHistory,
  ResetPassword,
  ScreenCastBlockList,
  SearchResult,
  Signup,
  SubscriptionDetail,
  SubscriptionResult,
  Subscriptions,
  ViewCertificate,
  ViewResourceV2,
} from "./pages/components";
import { useRecipeCookMutation } from "./generated/graphql";
import { useEffect } from "react";
import { Policy } from "./molecules/components";
import { IsGuest, IsIdle } from "./context/components";
import { TopicViewer } from "./pages/components/TopicViewer";
import { isIOS } from "react-device-detect";
import { TrainingInfo } from "./pages/components/TrainingInfo";

// Track user launcing the site
function AppTrackLaunchApp() {
  const [recipeCookMutateFunction] = useRecipeCookMutation();
  useEffect(() => {
    recipeCookMutateFunction({
      variables: {
        ingredient: {
          recipe_name: "app_launch",
          body: {
            timestamp: new Date().toISOString(),
            object: {},
            verb: "launched",
            context: {},
          },
        },
      },
      onError: (error) => {
        console.log(error.name);
      },
    });
  }, []);

  return <></>;
}

function App() {
  return (
    <BrowserRouter>
      <Favicon></Favicon>

      <AppTrackLaunchApp />

      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onReset={(error) => {
          // reset the state of your app so the error doesn't happen again
          window.location.reload();
        }}
      >
        <IsIdle />
        <IsGuest>
          <Routes>
            <Route path="/login" element={<Login />} />
            {/* <Route path="/dev" element={<Dev />} /> */}
            <Route path="/logout" element={<Logout />} />
            <Route path="/card" element={<CourseCard />} />
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route path="/" element={<Home />} />
            {/* Fallback ResourceViewer */}
            {/* <Route path="/view" element={<ViewResource />} /> */}
            <Route path="/view" element={<ViewResourceV2 />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="/courseviewer" element={<CourseViewer />} />
            <Route path="/catalogviewer" element={<CatalogList />} />
            <Route path="/screen" element={<ScreenCastBlockList />} />
            <Route path="/diksiyonaryo" element={<Diksiyonaryo />} />
            <Route path="/forumviewer" element={<ForumViewer />} />
            <Route path="/topicviewer" element={<TopicViewer />} />

            {/* Fallback AssessmentViewer */}
            {/* <Route path="/assessmentviewer" element={<AssessmentViewer />} /> */}
            <Route path="/assessmentviewer" element={<AssessmentViewerV2 />} />

            <Route path="/screen">
              <Route path="search" element={<SearchResult />} />
              <Route path=":slug" element={<ScreenCastBlockList />} />
            </Route>

            <Route path="/profile">
              <Route path="activitylog" element={<ActivityLog />} />
              <Route path="subscriptions">
                <Route index element={<Subscriptions />} />
                <Route path="" element={<Subscriptions />} />
                <Route path="new-subscription" element={<ChoosePlan />} />
                <Route path="manage" element={<SubscriptionDetail />} />
                <Route path="result" element={<SubscriptionResult />} />
              </Route>
              <Route path="purchases" element={<PurchaseHistory />} />
              <Route path="certification">
                <Route path="" element={<ProfileCoursesCertification />} />
                <Route path="courseactivity" element={<CourseActivity />} />
              </Route>
              <Route
                path="account-and-user-details"
                element={<AccountAndUserDetails />}
              />
              <Route path="training-info" element={<TrainingInfo />} />
            </Route>

            <Route path="/view-certificate" element={<ViewCertificate />} />
            {/* For callback backwards compatibility  */}
            <Route path="/auth/new" element={<AuthCallback />} />

            {/* <Route path="/casticulate" element={<Casticulate />} /> */}

            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </IsGuest>
      </ErrorBoundary>
      {isIOS ? null : <Policy />}
    </BrowserRouter>
  );
}

export default App;
