import { gql } from "@apollo/client";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../../../atoms/components/Button";
import { useMutation } from "@apollo/client";
import {
  Assessment_SubmissionMutation,
  Course_Block_Exam_SubmissionMutation,
  useAssessmentWithSubmissionsandSectionQuery,
  useResourceStartMutation,
} from "../../../generated/graphql";
import {
  LoadingCentered,
  SubmissionFeedback,
  Timer,
  Toast,
} from "../../../molecules/components";
import { IconButton } from "../../../molecules/components/IconButton";
import {
  AssessmentSection,
  CantNextAssessment,
  CourseBlockOpenModals,
  Modal,
  ResourceCompleteModal,
  SubmitAssessment,
} from "../../../organisms/components";
import { ResourceNavbar } from "../../../organisms/components/ResourceNavbar";
import { CourseBlock } from "../CourseViewer";
// import { Block } from "../CourseViewer";

export interface AnswerRequiredObject {
  isAnswered: boolean;
  itemUuid: string;
}

export interface AssessmentViewerProps {
  props?: any;
}
export const QUERY_ASSESSMENT_WITH_SUBMISSION = gql`
  query AssessmentWithSubmissionsandSection(
    $examTimeslotUuid: String!
    $resourceUuid: String!
  ) {
    assessment_view_screen(
      exam_timeslot_uuid: $examTimeslotUuid
      resource_uuid: $resourceUuid
    ) {
      resource {
        name
        description
        uuid
        type
        subtype
        cover_uri
      }
      exam_timeslot_uuid
      exam_timeslot {
        outline {
          exam {
            uuid
            title
            display_progress
            show_grade
            end_message
            time_limit
            has_complete_submission
            userscores
            is_graded
            show_grade_type
            sections {
              items {
                text
                type
                subtype
                choices {
                  is_correct
                  long_input
                  image_url
                  short_input
                  audio_url
                  item
                  uuid
                  order_id
                  created_at
                  modified_at
                }
                media_url
                required
                title
                instructions
                hint
                explanation
                is_graded
                order_id
                uuid
                value
                shuffle_choices
                section
                weight
                show_results
              }
              title
              uuid
              order_id
              is_graded
              item_limit
              created_by
              instructions
              shuffle_items
              modified_by
              exam
            }
          }
        }
        max_allowed_submissions
        uuid
        user_submissions {
          total_count
          final {
            count
            submissions {
              total_score
              timeslot
            }
          }
        }
      }
    }
  }
`;
export const QUERY_ASSESSMENT_VIEW = gql`
  query AssessmentDetailedViewScreen(
    $examTimeslotUuid: String!
    $resourceUuid: String!
  ) {
    assessment_view_screen(
      exam_timeslot_uuid: $examTimeslotUuid
      resource_uuid: $resourceUuid
    ) {
      resource {
        name
      }
      exam_timeslot {
        outline {
          exam {
            sections {
              items {
                text
                type
                subtype
                choices {
                  is_correct
                  long_input
                  image_url
                  short_input
                  audio_url
                  item
                  uuid
                  order_id
                  created_at
                  modified_at
                }
                media_url
                required
                title
                instructions
                hint
                explanation
                is_graded
                order_id
                uuid
                value
                shuffle_choices
                section
                weight
                show_results
              }
              title
              uuid
              order_id
              is_graded
              item_limit
              created_by
              instructions
              shuffle_items
              modified_by
              exam
            }
            time_limit
            title
            userscores
            uuid
            slug
            show_grade_type
            show_grade
            modified_by
            is_graded
            has_complete_submission
            end_message
            display_progress
            display_points
            display_missed
            display_correct
            description
            created_by
          }
        }
        created_by
        created_at
        description
        end
        start
        uuid
        open
        name
        user_submissions {
          total_count
        }
      }
    }
  }
`;

export const ASSESSMENT_SUBMISSION_MUTATION = gql`
  mutation AssessmentSubmission(
    $examTimeslotUuid: String!
    $submission: ExamSubmissionInput
  ) {
    assessment_submission(
      exam_timeslot_uuid: $examTimeslotUuid
      submission: $submission
    )
  }
`;
export const COURSE_BLOCK_EXAM_SUBMISSION_MUTATION = gql`
  mutation Course_block_exam_submission(
    $blockUuid: String!
    $submission: CourseBlockExamSubmission
  ) {
    course_block_exam_submission(
      block_uuid: $blockUuid
      submission: $submission
    )
  }
`;

export const RESOURCE_END_MUTATION_GQL = gql`
  mutation ResourceEndMutationOld(
    $resourceUuid: ID!
    $courseBlockUuid: String
    $courseScheduleUuid: String
  ) {
    resource_end(
      resource_uuid: $resourceUuid
      course_block_uuid: $courseBlockUuid
      course_schedule_uuid: $courseScheduleUuid
    ) {
      next_course_blocks {
        uuid
        title
        resource {
          uuid
        }
        coverUri: cover_uri
      }
    }
  }
`;

export function AssessmentViewer({ props }: AssessmentViewerProps) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const blockUuid = searchParams.get("course_block_uuid") as string;
  const scheduleUuid = searchParams.get("course_schedule_uuid") as string;
  const resourceUuid = searchParams.get("resource_uuid") as string;
  const examUuid = searchParams.get("exam_uuid") as string;
  const examTimeslotUuid = searchParams.get("exam_timeslot_uuid") as string;
  const courseUuid = searchParams.get("course_uuid") as string;
  const [passingMark, setPassingMark] = useState(50);
  const [submit, setSubmit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isRetakeOpen, setIsRetakeOpen] = useState(false);
  const [passOnSubmit, setPassOnSubmit] = useState(false);
  const [stopTimer, setStopTimer] = useState(false);
  const [formData, setFormData] = useState({});
  const [submission, setSubmission] = useState({});
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [score, setScore] = useState<{
    maxScore: number;
    totalScore: number;
    resultStatus: "passed" | "failed" | "pending" | undefined;
    canRetake: boolean;
    canGoNext: boolean;
  }>({
    maxScore: 0,
    totalScore: 0,
    resultStatus: undefined,
    canGoNext: false,
    canRetake: true,
  });
  const [results, setResults] =
    useState<{
      courseExamResults:
        | Course_Block_Exam_SubmissionMutation
        | null
        | undefined;
      examResults: Assessment_SubmissionMutation | null | undefined;
    }>();
  const [resourceCompleteModalOpen, setResourceCompleteModalOpen] =
    useState(false);
  const [cantGoNextModal, setCantGoNextModal] = useState(false);
  const parentScreenName = searchParams.get("from_screen");

  const [
    courseBlockResourceModalsAllowOpen,
    setCourseBlockResourceModalsAllowOpen,
  ] = useState(false);
  const [nextCourseBlockUuid, setNextCourseBlockUuid] = useState<string>();
  const [nextResourceUuid, setNextResourceUuid] = useState<string>();

  const assessment = useAssessmentWithSubmissionsandSectionQuery({
    variables: {
      examTimeslotUuid,
      resourceUuid: resourceUuid as string,
    },
    skip: !examTimeslotUuid,
  });
  const chances =
    assessment.data?.assessment_view_screen?.exam_timeslot
      ?.max_allowed_submissions;
  const name = assessment.data?.assessment_view_screen?.resource?.name;
  const sections =
    assessment.data?.assessment_view_screen?.exam_timeslot?.outline?.exam
      ?.sections;
  const timeLimit =
    assessment.data?.assessment_view_screen?.exam_timeslot?.outline?.exam
      ?.time_limit;
  const showResults =
    assessment.data?.assessment_view_screen?.exam_timeslot?.outline?.exam
      ?.show_grade;
  const [currentSection, setCurrentSection] = useState(
    sections ? sections[0] : null
  );

  const [
    resourceStartMutateFunction,
    {
      loading: loadingResourceStart,
      data: dataResourceStart,
      error: errorResourceStart,
    },
  ] = useResourceStartMutation({
    variables: {
      resourceUuid: resourceUuid as string,
      courseBlockUuid: blockUuid,
      courseScheduleUuid: scheduleUuid,
    },
  });
  const [
    resourceEndMutateFunction,
    {
      loading: loadingResourceEnd,
      data: dataResourceEnd,
      error: errorResourceEnd,
    },
  ] = useMutation(RESOURCE_END_MUTATION_GQL, {
    variables: {
      resourceUuid: resourceUuid as string,
      courseBlockUuid: blockUuid,
      courseScheduleUuid: scheduleUuid,
    },
  });

  // onNext
  const courseBlockEnd = async () => {
    if (score.canGoNext || passOnSubmit) {
      await resourceEndMutateFunction();
      setResourceCompleteModalOpen(true);
    } else {
      setCantGoNextModal(true);
    }
  };

  const changeSection = (event: any) => {
    sections?.forEach((section) => {
      if (section?.uuid === event.target.value) {
        setCurrentSection(section);
      }
    });
  };
  useEffect(() => {
    setCurrentSection(sections ? sections[0] : null);
  }, [sections]);
  useEffect(() => {
    resourceStartMutateFunction();
  }, [resourceUuid, blockUuid, scheduleUuid]);

  const navigateHome = () => {
    if (!courseUuid) {
      if (searchParams.get("search_value")) {
        navigate(
          `/screen/search?search_value=${searchParams.get("search_value")}`
        );
      } else {
        navigate(`/screen/${parentScreenName}`);
      }
    } else {
      navigate(
        `/courseviewer?course_uuid=${searchParams.get(
          "course_uuid"
        )}&from_screen=${parentScreenName}${
          parentScreenName === "search"
            ? `&search_value=${searchParams.get("search_value")}`
            : ""
        }`
      );
    }
  };

  // Used to check if all the required items is answered

  const [requiredState, setRequiredState] =
    useState<AnswerRequiredObject[] | null>(null);

  const [autoPending, setAutoPending] = useState<boolean>(false);

  // Initialize values for requiredState

  useEffect(() => {
    if (currentSection?.items) {
      // Setting Required State
      setRequiredState(
        (currentSection?.items || []).map((item): AnswerRequiredObject => {
          return item?.required
            ? { isAnswered: false, itemUuid: item?.uuid || "" }
            : { isAnswered: true, itemUuid: "itemNotRequired" };
        })
      );

      // Setting Type State
      setAutoPending(currentSection.items.some((item) => item?.type === "LA"));
    }
  }, [currentSection]);

  const updateRequiredValue = (requiredStateEntry: AnswerRequiredObject) => {
    // Switch isAnswered value true to false and vice versa
    const updatedState = requiredState?.map((stateItem) => {
      // Check for a change in isAnswered value
      if (
        stateItem.itemUuid === requiredStateEntry.itemUuid &&
        stateItem.isAnswered !== requiredStateEntry.isAnswered
      ) {
        return requiredStateEntry;
      } else return stateItem;
    });

    if (updatedState) setRequiredState(updatedState);
  };

  return (
    <>
      <ResourceNavbar
        resourceName={name || ""}
        isNextEnabled={!!blockUuid || courseUuid !== "null"}
        onNext={courseBlockEnd}
      />
      <CourseBlockOpenModals
        allowOpenModal={courseBlockResourceModalsAllowOpen}
        onModalClose={() => setCourseBlockResourceModalsAllowOpen(false)}
        courseBlockUuid={nextCourseBlockUuid}
        courseUuid={courseUuid as string}
        resourceUuid={nextResourceUuid}
      ></CourseBlockOpenModals>

      {assessment.loading ? (
        <LoadingCentered />
      ) : (
        <form
          aria-label="assessment"
          onSubmit={(e) => {
            e.preventDefault();

            if (
              !requiredState?.some(
                (item: AnswerRequiredObject) => item.isAnswered !== true
              ) ||
              requiredState.length === 0
            )
              setIsOpen(true);
            else {
              setIsToastOpen(true);
            }
          }}
        >
          {isToastOpen ? (
            <Toast
              message="Please complete the required items to proceed with your submission"
              toastStatus="error"
              onClose={() => setIsToastOpen(false)}
              closeDelay={3000}
            ></Toast>
          ) : (
            ""
          )}

          {dataResourceEnd ? (
            <ResourceCompleteModal
              nextBlocks={
                dataResourceEnd.resource_end?.[
                  "next_course_blocks"
                ] as CourseBlock[]
              }
              isOpen={resourceCompleteModalOpen}
              onRequestClose={() => setResourceCompleteModalOpen(false)}
              onBackToMainPage={() => {
                setResourceCompleteModalOpen(false);
                navigateHome();
              }}
              onBlockClick={(block) => {
                console.log(block);
                setNextCourseBlockUuid(block?.uuid || "");
                setNextResourceUuid(block.resource?.uuid || "");
                setCourseBlockResourceModalsAllowOpen(true);

                setResourceCompleteModalOpen(false);
              }}
            />
          ) : (
            <></>
          )}

          <Modal
            isOpen={cantGoNextModal}
            onRequestClose={() => setCantGoNextModal(false)}
          >
            <CantNextAssessment
              closeModal={() => setCantGoNextModal(false)}
              hasSubmitted={!!score.resultStatus}
              canRetake={score.canRetake}
              navigateHome={navigateHome}
            />
          </Modal>

          {results?.examResults || results?.courseExamResults ? (
            <div className="sticky top-0">
              <SubmissionFeedback
                passOnSubmit={passOnSubmit}
                results={score.resultStatus}
                totalScore={score.totalScore}
                maxScore={score.maxScore}
                retake={() => setIsRetakeOpen(true)}
                chances={chances}
                passingMark={passingMark}
                submissions={
                  results?.examResults?.assessment_submission
                    ?.submission_count ||
                  results?.courseExamResults?.course_block_exam_submission
                    ?.submission_count
                }
              />
            </div>
          ) : timeLimit ? (
            <div className="sticky top-0 flex justify-center py-2 bg-info-100 ">
              <Timer
                timeLimit={timeLimit}
                action={() => setSubmit(true)}
                stopTimer={stopTimer}
              ></Timer>
            </div>
          ) : (
            <></>
          )}

          <AssessmentSection
            section={currentSection}
            formData={setFormData}
            responses={
              (results?.examResults?.assessment_submission?.responses as any) ||
              (results?.courseExamResults?.course_block_exam_submission
                ?.responses as any)
            }
            updateRequiredValue={updateRequiredValue}
          />
          {/* Sections Chooser */}
          <SectionChooser
            changeSection={changeSection}
            sections={sections || []}
          ></SectionChooser>
          <SubmitAssessment
            setPassOnSubmit={setPassOnSubmit}
            setPassingMark={setPassingMark}
            maxScore={score.maxScore}
            totalScore={score.totalScore}
            courseUuid={courseUuid}
            timeLimit={timeLimit}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            blockUuid={blockUuid}
            resourceUuid={resourceUuid}
            formData={formData}
            examTimeslotUuid={examTimeslotUuid}
            setStopTimer={setStopTimer}
            setScore={setScore}
            setResults={setResults}
            score={score}
            results={results}
            isRetakeOpen={isRetakeOpen}
            setIsRetakeOpen={setIsRetakeOpen}
            submitExam={submit}
            setSubmit={setSubmit}
            chances={chances}
            submissions={
              results?.examResults?.assessment_submission?.submission_count ||
              results?.courseExamResults?.course_block_exam_submission
                ?.submission_count
            }
            autoPending={autoPending}
          />
        </form>
      )}
    </>
  );
}

export function SectionChooser({
  changeSection,
  sections,
}: {
  changeSection: ChangeEventHandler<HTMLSelectElement>;
  sections: any[];
}) {
  return (
    <div className="flex justify-between py-8 mx-2 border-t-2 border-gray-400 sm:mx-32">
      <IconButton
        type="button"
        icon="chevron_left"
        text="previous"
        color="mutedInverted"
      />
      <select
        onChange={changeSection}
        name="sections"
        id="sections"
        className="justify-center w-2/3 p-2 align-middle border border-gray-400 rounded"
      >
        {sections?.map((section) => {
          return (
            <option key={section?.uuid} value={section?.uuid as string}>
              {section?.title}
            </option>
          );
        })}
      </select>
      <IconButton
        type="button"
        iconPosition="right"
        icon="chevron_right"
        color="mutedInverted"
        text="next"
      />
    </div>
  );
}
