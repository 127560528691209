import { useContext, useMemo } from "react";
import {
  CasticulateNode,
  SlideContext,
} from "../../../pages/components/Casticulate";
import { MustacheTemplateRenderer } from "../../../atoms/components";
import camelcaseKeys from "camelcase-keys";
import { getEndSlideTemplate } from "./template/endSlideTemplate";

export interface CasticulateEndSlideProps {
  node?: CasticulateNode;
}

export function CasticulateEndSlide({ node }: CasticulateEndSlideProps) {
  const slideContext = useContext(SlideContext);
  const nodeData = camelcaseKeys(node?.data, { deep: true });

  const END_SLIDE_TEMPLATE_VIEW = useMemo(
    () => ({
      companyNameHeader: "GBF Class Builder",
      title: nodeData.title,
      header: nodeData.header || "Congratulations! You're ready.",
      subtext:
        nodeData.subText ||
        "You reviewed your upcoming lesson. You can now proceed!",
      imageUri: nodeData.imageUri,
      isHidden: node?.slideType === "START" ? "hidden" : "flex",
      progressBar: slideContext.progressBar,
      lastVisitedTitlePage: function () {
        return function (val: any, render: any) {
          const id = render(val);
          const progressBarItemTitle = slideContext.progressBar.find(
            (progressBarItem) =>
              progressBarItem.id === slideContext.lastVisitedTitlePageId
          )?.data.title;
          return id === slideContext.lastVisitedTitlePageId &&
            (progressBarItemTitle ? progressBarItemTitle.trim() !== "" : false)
            ? "pr-4"
            : "hidden";
        };
      },
      lastItemInProgressBar: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return id ===
            slideContext.progressBar[slideContext.progressBar.length - 1].id
            ? "hidden"
            : "";
        };
      },
    }),
    [slideContext]
  );

  return (
    <div className="box-border flex flex-col items-center h-full mx-auto">
      <MustacheTemplateRenderer
        template={getEndSlideTemplate(nodeData.theme)}
        view={END_SLIDE_TEMPLATE_VIEW}
      />
    </div>
  );
}
