import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Image, Icon, Button } from "../../../atoms/components";
import { useFileUpload } from "../../../helpers/FileUploadService";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { Modal } from "../Modal";
import { useErrorHandler } from "react-error-boundary";
import { LoadingCentered } from "../../../molecules/components";
import { Loading } from "../../../atoms/components";

const ViewResourceFileSubmissionExamItems = gql`
  query Items($examTimeslotUuid: String!, $resourceUuid: String!) {
    assessmentViewScreen: assessment_view_screen(
      exam_timeslot_uuid: $examTimeslotUuid
      resource_uuid: $resourceUuid
    ) {
      examTimeslot: exam_timeslot {
        outline {
          exam {
            sections {
              items {
                uuid
                type
              }
            }
          }
        }
      }
    }
  }
`;

const FileSubmissionInsideCourseMutation = gql`
  mutation Course_block_exam_submission(
    $blockUuid: String!
    $submission: CourseBlockExamSubmission
  ) {
    course_block_exam_submission(
      block_uuid: $blockUuid
      submission: $submission
    )
  }
`;

const FileSubmissionOutsideCourseMutation = gql`
  mutation Assessment_submission(
    $examTimeslotUuid: String!
    $submission: ExamSubmissionInput
  ) {
    assessment_submission(
      exam_timeslot_uuid: $examTimeslotUuid
      submission: $submission
    )
  }
`;

export interface ViewResourceFileSubmissionProps {
  title?: string;
  image?: string;
  timeslotUuid?: string;
}

export function ViewResourceFileSubmission({
  title = "I'm a Sample File Submission Resource",
  image = "https://via.placeholder.com/150x500",
  timeslotUuid = "ss",
}: ViewResourceFileSubmissionProps) {
  const [fileName, setFileName] = useState<string>("Upload file here");
  const [file, setFile] = useState<File>();
  const [notes, setNotes] = useState<string>("");
  const { loading, data, error, fileUpload } = useFileUpload({
    file: file,
  });
  const [isDoneMutation, setIsDoneMutation] = useState<boolean>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleClear = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  useErrorHandler(error);

  const [isSure, setIsSure] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  // GET SEARCH PARAMETERS
  const [searchParams, setSearchParams] = useSearchParams();

  // INSIDE COURSE SUBMISSION MUTATION OF FILE POSTED
  const [
    uploadInCourseMutation,
    {
      data: uploadInsideCourseData,
      error: uploadInsideCourseError,
      loading: uploadInsideCourseLoading,
    },
  ] = useMutation(FileSubmissionInsideCourseMutation);

  useErrorHandler(uploadInsideCourseError);

  // OUTSIDE COURSE SUBMISSION MUTATION OF FILE POSTED
  const [
    uploadOutsideCourseMutation,
    {
      data: uploadOutsideCourseData,
      error: uploadOutsideCourseError,
      loading: uploadOutsideCourseLoading,
    },
  ] = useMutation(FileSubmissionOutsideCourseMutation);
  useErrorHandler(uploadOutsideCourseError);

  // GET ITEM UUID LAZY THEN UPLOAD ON COMPLETE
  const [
    getItemUuid,
    { loading: itemUuidLoading, data: itemUuidData, error: itemUuidError },
  ] = useLazyQuery(ViewResourceFileSubmissionExamItems, {
    variables: {
      examTimeslotUuid: timeslotUuid,
      resourceUuid: searchParams.get("resource_uuid"),
    },
    onCompleted: (data) => {
      let itemMe: { uuid: string; type: string } =
        data?.assessmentViewScreen.examTimeslot.outline.exam.sections
          .find((section: any) => {
            return section.items.find((item: any) => item.type === "ME");
          })
          ?.items.find((item: any) => item.type === "ME");
      if (searchParams.get("course_block_uuid")) {
        uploadInCourseMutation({
          variables: {
            blockUuid: searchParams.get("course_block_uuid"),
            submission: {
              responses: [
                {
                  media_url: data?.uri,
                  item: itemMe.uuid,
                  input: notes,
                },
              ],
            },
          },
          onCompleted: (data) => {
            setIsDoneMutation(true);
          },
        });
      } else {
        uploadOutsideCourseMutation({
          variables: {
            examTimeslotUuid: timeslotUuid,
            submission: {
              responses: [
                {
                  item: itemMe.uuid,

                  long_input: "",
                  media_url: data?.uri,
                },
              ],
            },
          },
          onCompleted: (data) => {
            setIsDoneMutation(true);
          },
        });
      }
    },
  });

  useErrorHandler(itemUuidError);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var files = e.target.files;
    var filesArray = [].slice.call(files);
    filesArray.forEach((el: any) => {
      setFileName(el.name);

      setFile(el);
    });
  };

  useEffect(() => {
    fileUpload();
  }, [fileName]);

  return (
    <div className="flex flex-col items-center m-4">
      {/* Modal Before Submitting the File */}
      <Modal isOpen={isSure} onRequestClose={() => setIsSure(false)}>
        <div className="w-80 text-center">
          <h1 className="mb-2 text-2xl font-black">Are you sure?</h1>
          <h2 className="mb-2">
            This action cannot be undone after{" "}
            <span className="font-bold">submitting your file</span>{" "}
          </h2>

          {/* Action Buttons Div */}
          <div className="flex justify-around">
            <Button
              onClick={() => {
                getItemUuid();
                setIsSure(false);
                setIsSubmitted(true);
              }}
            >
              Confirm
            </Button>
            <Button color="primaryOutline" onClick={() => setIsSure(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <h1 className="text-lg font-bold text-gray-700 py-4">{title}</h1>
      <Image src={image} className="h-64"></Image>

      {/* FORM SUBMISSION */}
      <form
        className="w-full text-gray-500"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="bg-gray-100 px-8 py-6 my-2 ">
          <input
            type="file"
            id="actual-btn"
            ref={fileInputRef}
            hidden
            required
            onChange={(e) => {
              handleChange(e);
            }}
            disabled={isSubmitted}
          />
          <div>
            <label htmlFor="actual-btn" className="flex flex-col items-center">
              <Icon
                name="upload"
                className={fileName !== "Upload file here" ? "hidden" : ""}
              ></Icon>
              <div className="flex content-center">
                <Icon
                  name="attachment"
                  className={fileName !== "Upload file here" ? "" : "hidden"}
                ></Icon>
                <p className="mr-4 ml-2">{fileName}</p>
                <button
                  onClick={() => {
                    setFileName("Upload file here");
                    handleClear();
                  }}
                  disabled={isSubmitted}
                >
                  <Icon
                    name="close"
                    className={fileName !== "Upload file here" ? "" : "hidden"}
                  ></Icon>
                </button>
              </div>
            </label>
          </div>
        </div>

        <label htmlFor="notes-for-instructor">Notes for instructor</label>
        <textarea
          name="notes-for-instructor"
          id="notes-for-instructor"
          placeholder="Add notes here regarding your submitted file"
          className="block w-full h-28 mt-1 mb-2 border border-gray-400 rounded-md"
          disabled={isSubmitted}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />

        {/* CHanges when file has been submitted */}
        {isSubmitted ? (
          <div>
            {isDoneMutation ? (
              <div className="w-full p-4 bg-info-50 text-info-500">
                <h2 className=" font-bold">Your answer has been submitted</h2>
                <p>Some text here...</p>
              </div>
            ) : (
              <div className="w-20 mx-auto">
                <Loading></Loading>
              </div>
            )}
          </div>
        ) : (
          <Button
            className={`mx-auto disabled:bg-gray-600 ${
              isSubmitted ? "hidden" : ""
            }`}
            type="submit"
            color="secondary"
            disabled={
              data && !loading && fileName !== "Upload file here" ? false : true
            }
            onClick={() => {
              setIsSure(true);
            }}
            data-testid="submitButton"
          >
            {loading
              ? "Loading"
              : fileName === "Upload file here"
              ? "Select File"
              : "Submit"}
          </Button>
        )}
      </form>
    </div>
  );
}
