import { ColorThemes } from "../../../../pages/components/Casticulate/themes/colorThemes";

export function getEndSlideTemplate(theme: string | null) {
  const colorTheme = ColorThemes[theme as string] || ColorThemes["default"];

  const END_SLIDE_TEMPLATE = `
<div class="w-full flex flex-col items-center ${colorTheme.base} h-full rounded-lg relative  ">

<div class="flex font-titillium bg-white w-full px-8 py-4 text-4xl font-black shadow-md justify-between items-center " > 
    <div class="flex w-fit whitespace-nowrap mr-8">
      <p class=" text-teal-600 mr-1">GBF</p>  
      <p class=" text-teal-800 " >Class Builder</p>
    </div>

    <!-- Progress Bar -->
    <div class="hidden md:flex bg-[#B8DED9] w-fit h-fit p-2 rounded-full items-center text-[#038B8A] font-bold">
      {{#progressBar}}
        <section class="bg-white rounded-full p-1 relative w-fit h-fit space-x-1 flex items-center justify-center">
          <i class="material-icons " >
            {{#data.icon}}{{data.icon}}{{/data.icon}}{{^data.icon}}chevron_left{{/data.icon}} 
          </i>
        </section>
        <div class="h-2 w-4 bg-white {{#lastItemInProgressBar}}{{id}}{{/lastItemInProgressBar}}  " > 
        </div>
      {{/progressBar}}
    </div>
  </div>

  <div class="flex flex-col items-center my-8 flex-1 justify-center space-y-4">
    <p class="font-black text-black text-2xl md:text-4xl">
      {{header}}
    </p>
    <p class="font-semibold text-lg px-8 text-center text-black"> 
      {{subtext}}    
    </p>
    <button id="nextButton" class="w-fit px-12  py-2 mt-8 rounded-full font-titillium font-semibold text-center ${colorTheme.gradientButton} text-white relative active:opacity-70 hover:shadow-lg hover:opacity-80 transition-all duration-150 " >
      Finish
    </button>
    <div class="flex flex-1 w-full max-h-[40dvh] box-border mx-auto ">
      <img src="{{imageUri}}" class=" object-contain mx-auto" />
    </div>
  </div>

  <button id="backButton" class="${colorTheme.basicButtonText} p-2  rounded-3xl border-2 ${colorTheme.basicButtonBorder} w-fit active:opacity-70 hover:shadow-lg transition-all duration-150 mr-auto ml-4 mb-6 md:ml-8 md:mb-8"  >
    <i class="material-icons " id="previousPage" >arrow_back</i>
  </button>
  
</div>
`;

  if (theme === null || theme === undefined) {
    return END_SLIDE_TEMPLATE;
  }

  return END_SLIDE_TEMPLATE;
}
