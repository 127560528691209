import React, { useEffect, useMemo, useContext, useLayoutEffect } from "react";
import { MustacheTemplateRenderer } from "../../../atoms/components";
import { useState } from "react";
import {
  CasticulateNode,
  SlideContext,
} from "../../../pages/components/Casticulate";
import camelcaseKeys from "camelcase-keys";
import { getAssessmentTemplate } from "./template/assessmentTemplate";
import { marked } from "marked";

export interface CasticulateAssessmentProps {
  node?: CasticulateNode;
}

export function CasticulateAssessment({ node }: CasticulateAssessmentProps) {
  const slideContext = useContext(SlideContext);
  const previousRecord = slideContext.slideStates.find(
    (slideState) => slideState.slideUuid === node?.id
  )?.assessmentSlideState;

  const nodeData = useMemo(
    () => camelcaseKeys(node?.data, { deep: true }),
    [node]
  );

  const [choiceUuid, setChoiceUuid] = useState(
    previousRecord?.answers?.[0] || ""
  );
  const [choiceText, setChoiceText] = useState(
    previousRecord?.answersInText?.[0] || ""
  );
  const [isCorrect, setIsCorrect] = useState(
    previousRecord?.isCorrect || false
  );
  const [hasAnswered, setHasAnswered] = useState(
    previousRecord?.hasAnswered || null
  );

  const hasCorrectAnswer = nodeData.choices.some(
    (choice: any) => choice?.isCorrect === true
  );

  const neutralFeedback = nodeData.neutralFeedbackMessage || "";
  const correctAnswerFeedback = hasCorrectAnswer
    ? nodeData.correctFeedbackMessage || "Correct"
    : neutralFeedback;
  const wrongAnswerFeedback = hasCorrectAnswer
    ? nodeData.incorrectFeedbackMessage || "Incorrect"
    : neutralFeedback;

  const changedAnswer = previousRecord?.answers?.[0] !== choiceUuid;

  const ASSESSMENT_TEMPLATE_VIEW = useMemo(
    () => ({
      question: nodeData.question || "",
      choices: nodeData.choices || [],
      questionImage: () => {
        return !hasAnswered && nodeData.imageUri ? nodeData.imageUri : "hidden";
      },
      hasAnswered: hasAnswered,
      showCorrectness: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return choiceUuid === id && hasCorrectAnswer ? "" : " !hidden ";
        };
      },
      allChoiceHasImage: function () {
        return !nodeData?.choices.some(
          (choice: any) => choice?.imageUri === "" || choice?.image_uri === ""
        );
      },
      buttonText:
        hasAnswered && previousRecord?.answers?.includes(choiceUuid)
          ? "Continue"
          : "Answer to Continue",
      buttonId:
        hasAnswered && previousRecord?.answers?.includes(choiceUuid)
          ? "nextButton"
          : "submitButton",
      disabled: choiceUuid === "" ? "disabled" : "",
      disabledInput: previousRecord ? "disabled" : "",
      feedbackImage: () => {
        return hasAnswered && !changedAnswer
          ? previousRecord?.isCorrect
            ? nodeData.correctFeedbackImage !== ""
              ? nodeData.correctFeedbackImage
              : "hidden"
            : nodeData.incorrectFeedbackImage !== ""
            ? nodeData.incorrectFeedbackImage
            : "hidden"
          : "hidden";
      },
      progressBar: slideContext.progressBar,
      lastVisitedTitlePage: function () {
        return function (val: any, render: any) {
          const id = render(val);
          const progressBarItemTitle = slideContext.progressBar.find(
            (progressBarItem) =>
              progressBarItem.id === slideContext.lastVisitedTitlePageId
          )?.data.title;
          return id === slideContext.lastVisitedTitlePageId &&
            (progressBarItemTitle ? progressBarItemTitle.trim() !== "" : false)
            ? "pr-4"
            : "hidden";
        };
      },
      lastItemInProgressBar: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return id ===
            slideContext.progressBar[slideContext.progressBar.length - 1].id
            ? "hidden"
            : "";
        };
      },
    }),
    [
      nodeData,
      hasAnswered,
      choiceUuid,
      previousRecord,
      slideContext.lastVisitedTitlePageId,
      slideContext.progressBar,
    ]
  );

  function setChoice(this: HTMLElement) {
    const choiceUuid = this.getAttribute("value") || "";
    const choiceText = this.dataset.text || "";
    const isCorrect = this.dataset.isCorrect?.toLowerCase() === "true";
    setChoiceUuid(choiceUuid);
    setChoiceText(choiceText);
    setIsCorrect(isCorrect);
  }

  // Assessment Slide Use Effect
  useEffect(() => {
    // Get all button under an element with  id="choices"
    const buttons = document.querySelectorAll("#choices button");

    buttons.forEach((button) => {
      button.addEventListener("click", setChoice);
      // Setting Colors for Selected and Not Selected Choices

      // If Button value matches with choice uuid, change color
      if (button.getAttribute("value") === choiceUuid) {
        button.classList.add("bg-white");
        button.classList.remove("text-white");
        button.classList.add("text-black");
      }
      // If Button value did not match with choice uuid, go back to default color
      else {
        button.classList.remove("bg-white");
        button.classList.remove("text-black");
        button.classList.add("text-white");
      }
    });

    return () => {
      buttons.forEach((button) => {
        button.removeEventListener("click", setChoice);
      });
    };
  }, [
    choiceUuid,
    node,
    ASSESSMENT_TEMPLATE_VIEW,
    slideContext.lastVisitedTitlePageId,
  ]);

  // Post Submissiong Use Effect
  useEffect(() => {
    const feedbackTextElement = document.getElementById("feedbackText");
    const feedbackIconElement = document.getElementById("feedbackIcon");
    const questionElement = document.getElementById("question");

    if (feedbackTextElement && questionElement && feedbackIconElement) {
      if (previousRecord?.isCorrect) {
        if (hasCorrectAnswer) {
          feedbackIconElement.innerHTML = "new_releases";
          feedbackIconElement.classList.add("mr-2");
          feedbackTextElement.innerHTML = "You got it right!";
        }

        questionElement.innerHTML =
          marked.parse(correctAnswerFeedback) || correctAnswerFeedback;
      }
      if (!previousRecord?.isCorrect) {
        if (hasCorrectAnswer) {
          feedbackIconElement.innerHTML = "info";
          feedbackIconElement.classList.add("mr-2");
          feedbackTextElement.innerHTML = "Hmm, not quite";
        }

        questionElement.innerHTML =
          marked.parse(wrongAnswerFeedback) || wrongAnswerFeedback;
      }

      if (!hasCorrectAnswer) {
        feedbackIconElement.innerHTML = "info";
        feedbackIconElement.classList.add("mr-2");
        feedbackTextElement.innerHTML = "Thank you!";
      }

      if (hasAnswered === true && changedAnswer) {
        feedbackIconElement.innerHTML = "";
        feedbackIconElement.classList.remove("mr-2");

        feedbackTextElement.innerHTML = nodeData.title;
        questionElement.innerHTML = nodeData.question;
      }

      if (hasAnswered === null) {
        feedbackIconElement.innerHTML = "";
        feedbackIconElement.classList.remove("mr-2");
        feedbackTextElement.innerHTML = nodeData.title;
        questionElement.innerHTML = nodeData.question;
      }
    }
  }, [previousRecord, isCorrect, choiceUuid, ASSESSMENT_TEMPLATE_VIEW]);

  const submitFunction = () => {
    setHasAnswered(true);
    slideContext.appendSlideStates({
      slideUuid: node?.id || "",
      assessmentSlideState: {
        activityType: node?.slideType || "",
        answers: [choiceUuid],
        answersInText: [choiceText],
        isCorrect: isCorrect,
        hasAnswered: true,
        groupId: nodeData.groupId || "",
      },
    });
  };

  useLayoutEffect(() => {
    const required = true;
    const submitButton = document.getElementById("submitButton");
    submitButton?.addEventListener("click", submitFunction);

    slideContext.handleRerun();

    return () => {
      submitButton?.removeEventListener("click", submitFunction);
    };
  }, [ASSESSMENT_TEMPLATE_VIEW, choiceUuid]);

  return (
    <div className="box-border flex flex-col items-center h-full mx-auto">
      <MustacheTemplateRenderer
        template={getAssessmentTemplate(nodeData.theme)}
        view={ASSESSMENT_TEMPLATE_VIEW}
      />
    </div>
  );
}
