import { Icon } from "../../../atoms/components";
import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { Image, Button } from "../../../atoms/components";
import { randomBuriDog } from "../../../helpers/components/RandomBuriDog";
import {
  QUERY_LOGIN_SCREEN,
  getCastlmsDomain,
  getGuestLoginUrl,
} from "../Login";
import { LoadingCentered } from "../../../molecules/components";

export interface ErrorPageProps {}

type CustomError = Error & { status?: number };

export function ErrorPage({
  error,
  resetErrorBoundary,
}: {
  error?: CustomError | ApolloError;
  resetErrorBoundary?: any;
} = {}) {
  let navigate = useNavigate();

  const domain = getCastlmsDomain(window.location.host);
  const [isError401, setIsError401] = useState(true);

  const [
    checkIsGuest,
    { loading: loginLoading, data: loginData, error: loginError },
  ] = useLazyQuery(QUERY_LOGIN_SCREEN, {
    variables: {
      domain,
    },
    onCompleted: (data) => {
      const { client_id: clientId, hero_url: heroUrl } =
        data?.login_screen || {};
      if (data?.login_screen?.cast.attributes.default_login_as_guest) {
        window.location.href = getGuestLoginUrl({
          clientId: clientId || "",
        }).href;
        navigate({
          pathname: getGuestLoginUrl({
            clientId: clientId || "",
          }).href,
        });
      } else {
        let redirect = !window.location.pathname.includes("login")
          ? `?redirect=${encodeURIComponent(
              window.location.pathname + window.location.search
            )}`
          : "?redirect=/";

        navigate({
          pathname: "/login",
          search: redirect,
        });

        resetErrorBoundary();
      }
    },
  });

  useEffect(() => {
    // Check graphql specific errors
    if (error instanceof ApolloError) {
      let errorStatusCodes = error.graphQLErrors.map(
        (e: any) =>
          e.extensions?.response?.statusCode ||
          e.extensions?.response?.status ||
          e.extensions?.originalError?.statusCode
      );

      // Check for 401 and 403 status codes
      if (
        !window.location.pathname.includes("login") &&
        // (errorStatusCodes.includes(401) || errorStatusCodes.includes(403))
        errorStatusCodes.includes(401)
      ) {
        checkIsGuest();

        console.log(isError401);
      }
    } else {
      setIsError401(false);
    }
    const handleBackButton = (event: any) => {
      if (
        event.target.tagName !== "INPUT" &&
        event.target.tagName !== "TEXTAREA"
      ) {
        window.location.reload(); // Reload the page
      }
    };
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  return (
    <div className="h-screen flex items-center">
      {!isError401 ? (
        <div className="flex flex-col items-center mx-auto">
          <Image src={randomBuriDog()} className="w-2/3 max-h-[500px] " />
          {/* Instead of using &#  */}
          <h1 className="text-3xl text-primary-500 font-bold">Sorry :&#40;</h1>
          <h1 className="text-3xl text-primary-500 font-bold mb-4">
            Something went wrong
          </h1>

          {/* <pre className="bg-gray-100 text-gray-500 p-1 my-6 rounded">
        Unexpected Error Occured:{" "}
        {error?.name
          ? error?.name.replace(/([A-Z])/g, " $1").trim()
          : "Page not found"}
      </pre> */}
          <p className="w-2/3 mx-auto mb-6 text-center">
            You may refresh this page or click the button below to go back to
            home page.
          </p>
          <Button
            onClick={() => {
              navigate("/");
              window.location.reload();
            }}
          >
            Back to Home Page
          </Button>
          {/* <p>{error as ApolloError.graphQLErrors[0]}</p> */}

          {/* <div
        className="py-48 text-gray-500 flex flex-col items-center justify-center"
        role="alert"
      >
        <Icon name="sentiment_neutral" className="text-9xl"></Icon>
        <p className="text-3xl text-primary-500 font-bold">Something went wrong:</p>
        <div className=" p-2 my-3 border border-black">
          {/* <pre>{error?.message || ""}</pre> */}
          {/* Not sure po kung oks na to pero pinaka simple na po siguro yung hindi na naitn isasama yung error?.name */}
          {/* <pre>
            Unexpected Error Occured:{" "}
            {error?.name.replace(/([A-Z])/g, " $1").trim()}
          </pre>
        </div> */}

          {/* <button onClick={resetErrorBoundary}>Try Again</button> */}

          {/* Can't use link po e hindi nagrerender tong component */}
          {/* <a href="/">Go Back Home</a>
      </div>  */}
        </div>
      ) : (
        <div>
          {/* <button
            onClick={() => {
              setIsError401(false);
            }}
          >
            VOID
          </button> */}
          <LoadingCentered />
        </div>
      )}
    </div>
  );
}
