import { useEffect, useState } from "react";
import { Audio, Icon, Image } from "../../../atoms/components";
import {
  AnswerRequiredObject,
  ExamItemV2,
  InsideCourseResponses,
  OutsideCourseResponses,
  ExamChoiceV2,
} from "../../../pages/components/AssessmentViewerV2";

export interface CheckboxItemProps {
  item?: ExamItemV2;
  formData?: (data: any) => void;
  inputDisabled?: boolean;
  updateRequiredValue?: (requiredStateEntry: AnswerRequiredObject) => void;
}

type CheckedStateType = {
  checked: boolean;
  uuid: string;
};

export function CheckboxItem({
  item,
  formData = () => {},
  inputDisabled,
  updateRequiredValue = (requiredStateEntry: {
    isAnswered: boolean;
    itemUuid: string;
  }) => {},
}: CheckboxItemProps) {
  const initialCheckedState = item?.choices?.map((choice) => {
    return {
      checked: item?.draftValue?.choices?.some(
        (draftChoice) => draftChoice?.uuid === choice?.uuid
      ),
      uuid: choice?.uuid,
    };
  });
  const [checkedState, setCheckedState] = useState(initialCheckedState || []);

  useEffect(() => {
    formData((values: any) => ({
      ...values,
      [item?.uuid || ""]:
        checkedState
          .map((state) => {
            if (state.checked) return state.uuid;
          })
          .filter((uuid) => uuid !== undefined) || [],
    }));
  }, []);

  useEffect(() => {
    if (item?.required) {
      updateRequiredValue({
        isAnswered:
          checkedState?.some((checkedState) => checkedState.checked === true) ||
          false,
        itemUuid: item?.uuid || "",
      });
    }

    formData((values: any) => ({
      ...values,
      [item?.uuid || ""]: checkedState
        .map((state, index) => {
          if (state.checked) return state.uuid;
        })
        .filter((uuid) => uuid !== undefined),
    }));
  }, [checkedState]);

  const onValueChange = (choiceUuid: string) => {
    const updatedCheckedState = checkedState.map((currentState) => {
      if (currentState.uuid === choiceUuid) {
        return {
          checked: !currentState.checked,
          uuid: currentState.uuid,
        };
      } else {
        return currentState;
      }
    });
    setCheckedState(updatedCheckedState);
  };

  return (
    <div className="mb-16">
      <div className="text-lg font-bold">
        <span className="text-error-500">{item?.required ? "* " : ""}</span>
        {item?.text}
      </div>
      <div className={`py-4 ${item?.mediaUrl ? "" : "hidden"}`}>
        <Image
          src={item?.mediaUrl || ""}
          alt={item?.mediaUrl || ""}
          className={`w-full min-h-40 max-h-[30rem] object-contain bg-opacity-0 
          `}
        />
      </div>

      {item?.choices?.map((choice, index: number) => {
        if (choice)
          return (
            <div key={choice.uuid} className="my-8">
              <input
                className="rounded focus:border-primary-300 focus:ring-primary-300 text-primary-600"
                disabled={inputDisabled}
                type="checkbox"
                value={choice.uuid || ""}
                id={choice.uuid || ""}
                checked={checkedState[index].checked}
                onChange={() => onValueChange(choice?.uuid || "")}
              />
              <label htmlFor={choice.uuid || ""} className="ml-2">
                {choice.longInput || ""}
              </label>
              <Image
                src={choice.imageUrl || choice.mediaUrl || ""}
                alt={choice.imageUrl || choice.mediaUrl || ""}
                className={`w-full object-contain min-h-24 max-h-[30rem] bg-opacity-0 mt-4 ${
                  choice.imageUrl || choice.mediaUrl ? "" : "hidden"
                }`}
              />
              <Audio src={choice.audioUrl || ""} className="mt-4" />
            </div>
          );
      })}
    </div>
  );
}
