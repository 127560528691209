import React, { useEffect, useContext, useRef } from "react";
import camelcaseKeys from "camelcase-keys";
import { useState } from "react";
import {
  CasticulateAssessment,
  CasticulateAssessmentCheckbox,
  CasticulateAssessmentLongAnswer,
  CasticulateEndSlide,
  CasticulateExternalLink,
  CasticulateImageCarousel,
  CasticulateNavSlide,
  CasticulateResultsSlide,
  CasticulateSurvey,
  CasticulateTitleSlide,
  CasticulateVideoResource,
  CasticulateImageResource,
  ResourceCompleteModal,
  CasticulateAudioResource,
  CasticulatePdfResource,
  CasticulateAssessmentPoll,
  CasticulatePPST,
} from "../../../organisms/components";
import {
  useCasticulateViewScreenQuery,
  // useCasticulatePopPathMutation,
  CasticulateNode as CasticulateNodeOriginal,
  CasticulateEdge as CasticulateEdgeOriginal,
  useRecipeCookMutation,
  useCastIdQuery,
} from "../../../generated/graphql";
import { CamelCasedType } from "../../../helpers/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LastPageContext } from "../ViewResourceV2";

export type CasticulateNode = CamelCasedType<CasticulateNodeOriginal>;
export type CasticulateEdge = CamelCasedType<CasticulateEdgeOriginal>;

export const SlideContext = React.createContext<{
  slideStates: SlideState[];
  appendSlideStates: (incomingSlideState: SlideState) => void;
  handleRerun: () => void;
  currentSlideToDisplayId: string;
  handleSlideJumping: (id: string) => void;
  progressBar: CasticulateNode[];
  lastVisitedTitlePageId: string;
  resultsMaxScore: number;
  resultsRawScore: number;
}>({
  slideStates: [],
  appendSlideStates: () => {},
  handleRerun: () => {},
  currentSlideToDisplayId: "",
  handleSlideJumping: () => {},
  progressBar: [{}],
  lastVisitedTitlePageId: "",
  resultsMaxScore: 0,
  resultsRawScore: 0,
});

export type SlideState = {
  slideUuid: string;
  carouselSlideState?: {
    index?: number;
  };
  assessmentSlideState?: {
    activityType: string;
    hasAnswered?: boolean | null;
    answer?: string;
    answers?: string[];
    answersInText?: string[];
    isCorrect?: boolean | null;
    response?: string;
    groupId?: string;
    correctnessArray?: {
      id: string;
      isCorrect: boolean;
    }[];
  };
  pdfSlideState?: {
    images: string[];
  };
};

export const titilliumFontStyle = {
  fontFamily: "'Titillium Web', sans-serif",
};

export interface CasticulateProps {
  casticulateUuid: string;
}

export function Casticulate({ casticulateUuid }: CasticulateProps) {
  const [openCompleteModal, setOpenCompleteModal] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const courseUuid = searchParams.get("course_uuid");
  const resourceUuid = searchParams.get("resource_uuid");
  const parentScreenName = searchParams.get("from_screen");
  const navigateHome = () => {
    if (!courseUuid) {
      if (searchParams.get("search_value")) {
        navigate(
          `/screen/search?search_value=${searchParams.get("search_value")}`
        );
      } else {
        navigate(`/screen/${parentScreenName}`);
      }
    } else {
      navigate(
        `/courseviewer?course_uuid=${searchParams.get(
          "course_uuid"
        )}&from_screen=${parentScreenName}${
          parentScreenName === "search"
            ? `&search_value=${searchParams.get("search_value")}`
            : ""
        }`
      );
    }
  };

  const lastPageContext = useContext(LastPageContext);

  // Pop path
  // const [popPath, popPathResponse] = useCasticulatePopPathMutation({
  //   variables: {
  //     ids: {
  //       casticulate_uuid: casticulateUuid,
  //     },
  //   },
  //   onError: () => {
  //     console.log("No path to pop");
  //   },
  // });

  const { data, loading } = useCasticulateViewScreenQuery({
    variables: {
      ids: {
        casticulate_uuid: casticulateUuid,
      },
    },
    fetchPolicy: "no-cache",
  });

  const castIdQuery = useCastIdQuery();

  const allNodes = data?.casticulateViewScreen?.casticulateGraph?.nodes;
  const [path, setPath] = useState<string[]>([]);

  const [titleSlides, setTitleSlides] = useState<
    { id: string; data: any; path: string[] }[]
  >([]);

  const [possibleRoute, setPossibleRoute] = useState<CasticulateNode[]>([{}]);

  useEffect(() => {
    setPossibleRoute(
      (data?.casticulateViewScreen?.casticulateGraph
        ?.possibleRoutes?.[0] as CasticulateNode[]) || [{}]
    );
  }, [data]);

  const [lastVisitedTitlePage, setLastVisitedTitlePage] = useState<string>(
    data?.casticulateViewScreen?.casticulateGraph?.possibleRoutes?.[0]?.[0]
      ?.id || ""
  );

  // useEffect(() => {
  //   const newTitleSlides = titleSlides.filter((titleSlide) =>
  //     path.includes(titleSlide.id)
  //   );
  //   setTitleSlides(newTitleSlides);
  // }, [path]);

  const [currentSlideToDisplayId, setCurrentSlideToDisplayId] =
    useState<string>("");

  useEffect(() => {
    setCurrentSlideToDisplayId(
      data?.casticulateViewScreen?.casticulateGraph?.edges?.find(
        (edge) => edge?.fromNode?.slideType === "START"
      )?.fromNode?.id || ""
    );
  }, [data]);

  const foundNode = allNodes?.find(
    (node) => node?.id === currentSlideToDisplayId
  );

  function renderFoundNode() {
    const slideType = foundNode?.slideType;

    if (slideType !== "END") {
      lastPageContext.handleIsSlideLastPage(false);
    }

    if (slideType === "IMAGE") {
      return (
        <CasticulateImageResource key={foundNode?.id} node={foundNode || {}} />
      );
    }

    if (slideType === "PPST") {
      return <CasticulatePPST key={foundNode?.id} node={foundNode || {}} />;
    }

    if (slideType === "VIDEO") {
      return (
        <CasticulateVideoResource key={foundNode?.id} node={foundNode || {}} />
      );
    }

    if (slideType === "AUDIO") {
      return (
        <CasticulateAudioResource key={foundNode?.id} node={foundNode || {}} />
      );
    }

    if (slideType === "PDF") {
      return (
        <CasticulatePdfResource key={foundNode?.id} node={foundNode || {}} />
      );
    }

    if (slideType === "START") {
      return (
        <CasticulateTitleSlide key={foundNode?.id} node={foundNode || {}} />
      );
    }
    if (slideType === "IMAGE_CAROUSEL")
      if (foundNode?.data.uris)
        return (
          <CasticulateImageCarousel
            key={foundNode?.id}
            node={foundNode || {}}
          />
        );
    if (slideType === "TITLE") {
      return (
        <CasticulateNavSlide
          key={foundNode?.id}
          node={foundNode || {}}
          titleSlides={titleSlides}
        />
      );
    }
    if (slideType === "EXTERNAL_LINK")
      return (
        <CasticulateExternalLink key={foundNode?.id} node={foundNode || {}} />
      );
    if (slideType === "END") {
      lastPageContext.handleIsSlideLastPage(true);
      return <CasticulateEndSlide key={foundNode?.id} node={foundNode || {}} />;
    }
    if (slideType === "MULTIPLE_CHOICE")
      return (
        <CasticulateAssessment key={foundNode?.id} node={foundNode || {}} />
      );
    if (slideType === "POLL")
      return (
        <CasticulateAssessmentPoll key={foundNode?.id} node={foundNode || {}} />
      );
    if (slideType === "TEXT")
      return (
        <CasticulateAssessmentLongAnswer
          key={foundNode?.id}
          node={foundNode || {}}
        />
      );
    if (slideType === "CHECKBOX")
      return (
        <CasticulateAssessmentCheckbox
          key={foundNode?.id}
          node={foundNode || {}}
        />
      );
    if (slideType === "ACTIVITY_RESULTS") {
      // lastPageContext.handleIsSlideLastPage(true);

      return (
        <CasticulateResultsSlide key={foundNode?.id} node={foundNode || {}} />
      );
    }
    if (slideType === "SURVEY")
      return <CasticulateSurvey key={foundNode?.id} node={foundNode || {}} />;
    else {
      return null;
    }
  }

  // Change possible Routes depending on foundNode value
  useEffect(() => {
    if (foundNode) {
      const isProgressNode =
        foundNode.slideType === "TITLE" ||
        foundNode.slideType === "START" ||
        foundNode.slideType === "END";

      // If found node is a title, start, or end slide
      if (isProgressNode) {
        if (
          !titleSlides.some((titleSlide) => titleSlide.id === foundNode?.id)
        ) {
          setTitleSlides((prev) => [
            ...prev,
            {
              data: foundNode?.data,
              id: foundNode?.id || "",
              path: path,
            },
          ]);
        }
      } else {
        setTitleSlides((prev) =>
          prev.filter((titleSlide) => path.includes(titleSlide.id))
        );
      }
    }
  }, [foundNode]);

  // Setting possible route
  useEffect(() => {
    const newPossibleRoute =
      data?.casticulateViewScreen?.casticulateGraph?.possibleRoutes?.find(
        (possibleRoute) => {
          const idsForThisRoute = possibleRoute?.map((route) => route?.id);
          return titleSlides.every((titleSlide) =>
            idsForThisRoute?.includes(titleSlide.id)
          );
        }
      );
    setPossibleRoute(newPossibleRoute as CasticulateNode[]);
  }, [titleSlides, foundNode]);

  const [slideStates, setSlideStates] = useState<SlideState[]>([]);

  const appendSlideStates = (incomingSlideState: SlideState) => {
    // Replace existing slide state
    let updatedSlideStates: SlideState[] = slideStates.map((slideState) => {
      if (slideState.slideUuid === incomingSlideState.slideUuid) {
        return incomingSlideState;
      }
      return slideState;
    });

    // If Slide states is empty
    if (
      updatedSlideStates.length === 0 ||
      !updatedSlideStates.some(
        (slideState) => slideState.slideUuid === incomingSlideState.slideUuid
      )
    ) {
      setSlideStates((prev) => [...updatedSlideStates, incomingSlideState]);
    } else {
      setSlideStates([...updatedSlideStates]);
    }
  };

  const resultRawScore = slideStates
    .filter(
      (state) =>
        (state.assessmentSlideState?.activityType === "MULTIPLE_CHOICE" &&
          state.assessmentSlideState.groupId ===
            camelcaseKeys(foundNode?.data).groupId) ||
        (state.assessmentSlideState?.activityType === "CHECKBOX" &&
          state.assessmentSlideState.groupId ===
            camelcaseKeys(foundNode?.data).groupId)
    )
    .filter(
      (assessmentSlideState) =>
        assessmentSlideState.assessmentSlideState?.isCorrect
    ).length;

  const resultMaxScore = slideStates.filter(
    (state) =>
      (state.assessmentSlideState?.activityType === "MULTIPLE_CHOICE" &&
        state.assessmentSlideState.groupId ===
          camelcaseKeys(foundNode?.data).groupId) ||
      (state.assessmentSlideState?.activityType === "CHECKBOX" &&
        state.assessmentSlideState.groupId ===
          camelcaseKeys(foundNode?.data).groupId)
  ).length;

  useEffect(() => {
    // Fetch Material Icons
    const materialIconsLink = document.createElement("link");
    materialIconsLink.rel = "stylesheet";
    materialIconsLink.href =
      "https://fonts.googleapis.com/icon?family=Material+Icons";
    // Add font-display property to control loading behavior
    materialIconsLink.setAttribute("media", "all");
    materialIconsLink.setAttribute("onload", "this.media='all'");
    document.head.appendChild(materialIconsLink);

    const titilliumWebLink = document.createElement("link");
    titilliumWebLink.rel = "stylesheet";
    titilliumWebLink.href =
      "https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap";
    document.head.appendChild(titilliumWebLink);

    return () => {
      // Cleanup: remove the link elements when the component is unmounted
      // document.head.removeChild(tailwindLink);
      document.head.removeChild(materialIconsLink);
      document.head.removeChild(titilliumWebLink);
    };
  }, []);

  const [rerunNav, setRerunNav] = useState<boolean>(false);

  // This is to trigger
  function handleRerun() {
    setRerunNav((prev) => !prev);
  }

  const [nodeScreenDuration, setNodeScreenDuration] = useState(1);
  const [cookMutation] = useRecipeCookMutation();
  const nodeScreenDurationRef = useRef(1);

  // Accumulated duration spent on the slide
  useEffect(() => {
    const screenDurationTimer = setTimeout(() => {
      if (!loading && data) {
        const currentDuration = nodeScreenDuration + 1;
        setNodeScreenDuration(currentDuration);
        nodeScreenDurationRef.current = currentDuration;
      }
    }, 1000);

    return () => {
      clearTimeout(screenDurationTimer); // Clear the setTimeout
    };
  }, [data, loading, foundNode, nodeScreenDuration]);

  // Resets node screen duration
  function clearNodeScreenDuration() {
    setNodeScreenDuration(1);
    nodeScreenDurationRef.current = 1;
  }

  function cookCasticulateAction(
    slideType: string,
    nodeScreenDuration: number
  ) {
    const activitySlides = ["MULTIPLE_CHOICE", "CHECKBOX", "POLL", "SURVEY"];
    if (!activitySlides.includes(slideType)) {
      // Non Activity Cook Mutation
      cookMutation({
        variables: {
          ingredient: {
            recipe_name: "casticulate_slide",
            body: {
              verb: "experienced",
              timestamp: new Date().toISOString(),
              object: {
                casticulate_node_id: foundNode?.id || "",
                // slide_type: foundNode?.slideType || "",
                activity_type: foundNode?.slideType || "",
              },
              result: {
                seconds: nodeScreenDuration,
                duration: nodeScreenDuration,
              },
              context: {
                resource_id: resourceUuid || "",
                casticulate_id: casticulateUuid || "",
                cast_id: castIdQuery.data?.browserTitleBar?.id || "",
                domain: "https://" + window.location.host || "",
                registration: courseUuid || "",
              },
            },
          },
        },
        onCompleted: () => {
          clearNodeScreenDuration();
        },
      });
    } else {
      const activitySlideToCook = slideStates.find(
        (slideStates) => slideStates.slideUuid === foundNode?.id
      );

      // ACTIVITY COOK MUTATION
      cookMutation({
        variables: {
          ingredient: {
            recipe_name: "casticulate_activity",
            body: {
              verb: "experienced",
              timestamp: new Date().toISOString(),
              object: {
                casticulate_node_id: foundNode?.id || "",
                // slide_type: foundNode?.slideType || "",
                activity_type: foundNode?.slideType || "",
              },
              result: {
                score: activitySlideToCook?.assessmentSlideState?.isCorrect
                  ? 1
                  : 0,
                seconds: nodeScreenDuration,
                duration: nodeScreenDuration,
                response:
                  activitySlideToCook?.assessmentSlideState?.answersInText?.join(
                    ","
                  ),
              },
              context: {
                resource_id: resourceUuid || "",
                casticulate_id: casticulateUuid || "",
                cast_id: castIdQuery.data?.browserTitleBar?.id || "",
                domain: "https://" + window.location.host || "",
                registration: courseUuid || "",
              },
            },
          },
        },
        onCompleted: () => {
          clearNodeScreenDuration();
        },
      });
    }
  }

  function handleNextSlideIndex(event: Event) {
    cookCasticulateAction(
      foundNode?.slideType || "",
      nodeScreenDurationRef.current
    );
    const triggerId =
      (event.currentTarget as HTMLElement).getAttribute("data-trigger-id") ||
      "";
    const setFoundNode = (edgeToUse: CasticulateEdge) => {
      if (foundNode?.slideType !== "END") {
        setCurrentSlideToDisplayId((prev) => edgeToUse?.toNode?.id || "");
        setPath((prev) => [...prev, edgeToUse?.fromNode?.id || ""]);
      } else {
        lastPageContext.handleTrainingIndicatorsEarned();
      }
    };

    let currentSlideState = slideStates.find(
      (slideState) => slideState.slideUuid === currentSlideToDisplayId
    );

    let edgeToUse;
    let edges = data?.casticulateViewScreen?.casticulateGraph?.edges;
    if (triggerId === "") {
      edgeToUse =
        edges
          ?.filter((edge) => {
            return edge?.triggers?.some(
              (trigger) => trigger?.refId === currentSlideState?.slideUuid
            );
          })
          .filter((acceptedEdges) => {
            // Pag merong isa na wala sa triggers
            let flag = true;

            // If length of triggers and answers is not the same, auto not match
            if (
              acceptedEdges?.triggers?.length !==
              currentSlideState?.assessmentSlideState?.answers?.length
            ) {
              return false;
            }
            acceptedEdges?.triggers?.forEach((trigger) => {
              // If something is not present in tri
              if (
                trigger?.value &&
                !currentSlideState?.assessmentSlideState?.answers?.includes(
                  trigger?.value
                )
              ) {
                flag = false;
              }
            });

            return flag;
          })
          .find((edge) => {
            return edge?.fromNode?.id === currentSlideToDisplayId;
          }) ||
        data?.casticulateViewScreen?.casticulateGraph?.edges?.find(
          (edge) =>
            edge?.fromNode?.id === currentSlideToDisplayId &&
            // Check if the answer is the same slide state and trigger field WIP
            edge.triggers?.some(
              (trigger) =>
                trigger?.refId === currentSlideToDisplayId &&
                trigger?.action === "CONTINUE"
            )
        ) ||
        data?.casticulateViewScreen?.casticulateGraph?.edges?.find(
          (edge) => edge?.fromNode?.id === currentSlideToDisplayId
        );
    } else {
      edgeToUse = edges?.find((edge) =>
        edge?.triggers?.some((trigger) => trigger?.id === triggerId)
      );
    }
    setFoundNode(edgeToUse || {});
  }

  function handlePreviousSlideIndex() {
    setCurrentSlideToDisplayId(path?.[path.length - 1] || "");
    const newPath = [...path];
    newPath.pop();
    setPath(newPath);
  }

  function handleSlideJumping(id: string) {
    setPath(titleSlides.find((titleSlide) => titleSlide.id === id)?.path || []);
    setCurrentSlideToDisplayId(id);
  }

  useEffect(() => {
    // Navigation listener attachment logic
    const nextButtons = document.querySelectorAll("#nextButton");
    const backButton = document.getElementById("backButton");

    nextButtons.forEach((nextButton) => {
      nextButton?.addEventListener("click", handleNextSlideIndex);
    });
    backButton?.addEventListener("click", handlePreviousSlideIndex);
    if (foundNode?.slideType === "START") {
      backButton?.classList.remove("flex");
      backButton?.classList.add("hidden");
    } else {
      backButton?.classList.add("flex");
      backButton?.classList.remove("hidden");
    }

    return () => {
      backButton?.removeEventListener("click", handlePreviousSlideIndex);
      nextButtons.forEach((nextButton) => {
        nextButton?.removeEventListener("click", handleNextSlideIndex);
      });
    };
  }, [currentSlideToDisplayId, rerunNav, slideStates]);

  // Last title slide visited
  useEffect(() => {
    // Getting and setting last visited title page
    const titleSlidesIds = titleSlides.map((titleSlide) => {
      return titleSlide.id;
    });

    let tempLastVisitedTitlePageId = lastVisitedTitlePage;

    if (
      path.length !== 0 &&
      currentSlideToDisplayId !== tempLastVisitedTitlePageId
    ) {
      while (!path.includes(tempLastVisitedTitlePageId)) {
        titleSlidesIds.pop();
        tempLastVisitedTitlePageId =
          titleSlidesIds?.[titleSlidesIds.length - 1] || "";
      }
    }
    setLastVisitedTitlePage((prev) => tempLastVisitedTitlePageId);

    if (
      foundNode?.slideType === "START" ||
      foundNode?.slideType === "END" ||
      foundNode?.slideType === "TITLE"
    ) {
      setLastVisitedTitlePage((prev) => foundNode?.id || "");
    }

    handleRerun();
  }, [
    currentSlideToDisplayId,
    slideStates,
    path,
    lastVisitedTitlePage,
    possibleRoute,
    foundNode,
    data,
  ]);

  return (
    <div className="w-full h-[94dvh] overflow-clip ">
      <SlideContext.Provider
        value={{
          slideStates,
          appendSlideStates,
          handleRerun,
          currentSlideToDisplayId: currentSlideToDisplayId,
          handleSlideJumping,
          progressBar: possibleRoute,
          lastVisitedTitlePageId: lastVisitedTitlePage,
          resultsMaxScore: resultMaxScore,
          resultsRawScore: resultRawScore,
        }}
      >
        <ResourceCompleteModal
          isOpen={openCompleteModal}
          onRequestClose={() => {
            setOpenCompleteModal(false);
          }}
          onBackToMainPage={() => {
            setOpenCompleteModal(false);
            navigateHome();
          }}
        />
        <div className="h-full ">{renderFoundNode()}</div>
      </SlideContext.Provider>
    </div>
  );
}
