import { useState } from "react";
import { ResourceNavbar } from "../../../organisms/components";
import { gql, useQuery } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingCentered } from "../../../molecules/components";
import { Image, PillLabel, Button, Markdown } from "../../../atoms/components";
import { titleCase } from "../../../helpers/TitleCase";
import { useHumanizeResourceType } from "../../../helpers/ResourceType";
import { useCourseActivityCheckerQuery } from "../../../generated/graphql";

export interface CourseActivityProps {}

export function CourseActivity({}: CourseActivityProps) {
  const [searchParams] = useSearchParams();
  const { humanize } = useHumanizeResourceType();
  const { data, loading, error } = useCourseActivityCheckerQuery({
    variables: {
      courseUuid: searchParams.get("course_uuid") || "",
      scheduleUuid: searchParams.get("course_schedule_uuid") || "",
    },
  });
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const navigate = useNavigate();

  function goToCourse() {
    if (
      data?.courseProgressScreen?.learnerCourseStatus?.scheduleDetails
        ?.status === "ONGOING"
    )
      navigate(
        `/courseviewer?course_uuid=${searchParams.get(
          "course_uuid"
        )}&course_schedule_uuid=${searchParams.get(
          "course_schedule_uuid"
        )}&status=${searchParams.get("status") || ""}&from_screen=profile`
      );
  }

  return (
    <div>
      <ResourceNavbar
        resourceName={data?.courseProgressScreen?.course?.title || ""}
      ></ResourceNavbar>
      {data ? (
        <div className="mx-20 ">
          {/* Course Activity Header  */}
          <div className="flex w-full my-8">
            <Image
              src={data?.courseProgressScreen?.course?.coverUri || ""}
              className="w-20 mr-4 md:w-28 md:h-28"
              role="button"
              onClick={goToCourse}
            ></Image>
            <div className="flex flex-col flex-1">
              <div
                className={`text-xs overflow-hidden ${
                  seeMore ? "h-fit" : "h-28"
                } `}
              >
                <h1
                  className="text-lg font-semibold"
                  role="button"
                  onClick={goToCourse}
                >
                  {data?.courseProgressScreen?.course?.title}
                </h1>
                <Markdown
                  text={data?.courseProgressScreen?.course?.description || ""}
                />
                <PillLabel
                  status={
                    data?.courseProgressScreen?.course?.hasUserComplete
                      ? "passed"
                      : ""
                  }
                >
                  {data?.courseProgressScreen?.course?.hasUserComplete
                    ? "COMPLETE"
                    : ""}
                </PillLabel>
              </div>
              {data?.courseProgressScreen?.course?.description &&
              data?.courseProgressScreen?.course.description.length > 825 ? (
                <button
                  onClick={() => {
                    setSeeMore(!seeMore);
                    if (seeMore === true) window.scrollTo(0, 0);
                  }}
                >
                  {seeMore ? "See less" : "See More"}
                </button>
              ) : null}
            </div>
          </div>

          {/* View Certificate Button */}
          {data?.courseProgressScreen?.learnerCourseStatus
            ?.courseCertificateUrl && (
            <a
              href={
                data?.courseProgressScreen?.learnerCourseStatus
                  .courseCertificateUrl || ""
              }
            >
              <Button className="w-full mb-8">View Certificate</Button>
            </a>
          )}

          {/* Course Outline */}
          <div>
            <h1 className="mb-2 text-xl font-bold">Course Progress</h1>
            {/* Outline Divs */}
            {data?.courseProgressScreen?.outlineProgress?.map(
              (outline: any) => {
                return (
                  <div key={outline.title} className="mb-4">
                    <h2 className="mb-2 text-lg font-semibold">
                      {outline.title}
                    </h2>
                    {/* Block cards */}
                    {outline.blocks.map((block: any) => {
                      return (
                        <div
                          key={block.title}
                          className="flex pb-2 mb-2 border-b-2"
                        >
                          <Image
                            src={block.coverUri}
                            className="mr-4 w-28 h-28"
                            role="button"
                            onClick={goToCourse}
                          ></Image>
                          <div className="font-medium">
                            <div className="ml-1">
                              <h3 className="text-gray-500 ">
                                {humanize({
                                  type: block.resourceType,
                                  subtype: block.resourceSubType,
                                })}
                              </h3>
                              <button className="" onClick={goToCourse}>
                                {block.title}
                              </button>
                            </div>

                            <PillLabel status={block.status}>
                              {block.status && block.status !== "not_started"
                                ? block.status.replace("_", " ").toUpperCase()
                                : null}
                            </PillLabel>
                            <p className="mt-2 ml-1">
                              {block.status &&
                              block.status !== "not_started" &&
                              block.scoreMax !== null
                                ? `Score: ${block.scoreRaw}/${block.scoreMax}`
                                : null}
                            </p>
                          </div>
                          {/* <Icon
                            name={
                              block.status === "passed" ? "check_circle" : ""
                            }
                            className="ml-auto text-success-500"
                          ></Icon> */}
                        </div>
                      );
                    })}
                  </div>
                );
              }
            )}
          </div>
        </div>
      ) : (
        <LoadingCentered />
      )}
    </div>
  );
}
