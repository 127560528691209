import React from "react";

export interface ViewResourceLayoutProps {
  navbar?: React.ReactNode;
  children?: React.ReactNode;
}

export function ViewResourceLayout({
  navbar,
  children,
}: ViewResourceLayoutProps) {
  return (
    <div className="flex flex-col w-full h-screen">
      {navbar}
      <div className="w-full h-[94dvh] ">{children}</div>
    </div>
  );
}
