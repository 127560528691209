import { resourceTypeIcon } from "../../../helpers";
import { Icon } from "../../../atoms/components";

export interface CatalogCardLabelProps {
  type?: string | null | undefined;
  subtype?: string | null | undefined;
  isCourse?: boolean;
  success?: boolean | null | undefined;
  resourceIconStyle?: any;
}

export function CatalogCardLabel({
  type,
  subtype,
  isCourse = false,
  success,
  resourceIconStyle,
}: CatalogCardLabelProps) {
  let courseSuccessIcon = null;

  // Dont render icon if success is null or undefined
  if (success === true) {
    courseSuccessIcon = "check_circle";
  } else if (success === false) {
    courseSuccessIcon = "error";
  } else {
    courseSuccessIcon = "";
  }

  return (
    <div className="gap-1 px-1 text-xl bg-white rounded-lg shadow-sm w-fit">
      {isCourse && courseSuccessIcon && success ? (
        <Icon
          role="img"
          name={courseSuccessIcon}
          className={success ? "text-success-500" : "text-error-500"}
        />
      ) : null}

      <Icon
        role="img"
        className="text-gray-500"
        style={resourceIconStyle}
        name={resourceTypeIcon(subtype || type)}
      ></Icon>
    </div>
  );
}
